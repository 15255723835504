import React from "react";
import { Table, Modal } from "antd";
import getFormattedISTDateTimeFromTimestamp from "../../utils/formatTimestamp";

class SubscriptionBenefitsTable extends React.Component {
  convertUTCToDate = (utc) => {
    const ne = new Date(utc);
    return ne;
  };

  GetTimestamp = (date) => {
    const { day, monthName, year, hours, minutes, seconds } =
      getFormattedISTDateTimeFromTimestamp(date);
    return day + " " + monthName + " " + year;
  };
  render() {
    const columns = [
      {
        title: " ",
        dataIndex: "rowTitle",
        key: "rowTitle",
      },
      {
        title: "Discover",
        dataIndex: "discover",
        key: "discover",
      },
      {
        title: "Transform",
        dataIndex: "transform",
        key: "transform",
      },
      {
        title: "Cruize",
        dataIndex: "cruize",
        key: "cruize",
      },
      {
        title: "DockerOnHost",
        dataIndex: "dockeronhost",
        key: "dockeronhost",
      },
      {
        title: "PolymorphLinux",
        dataIndex: "workload",
        key: "workload",
      },
      {
        title: "DiscoverLite",
        dataIndex: "cloudlease",
        key: "cloudlease",
      },
    ];

    const data = this.props.benefitsTable;

    const new_dict = [
      {
        rowTitle: "Count",
        discover: data.discoverlicensecount,
        transform: data.transformlicensecount,
        cruize: data.cruizelicensecount,
        cloudlease: data.cloudleaselicensecount,
        workload: data.workloadlicensecount,
        dockeronhost: data.dockeronhostlicensecount,
      },
      {
        rowTitle: "Start Date",
        discover: this.GetTimestamp(
          this.convertUTCToDate(data.discoverstartdate * 1000)
        ),
        transform: this.GetTimestamp(
          this.convertUTCToDate(data.transformstartdate * 1000)
        ),
        cruize: this.GetTimestamp(
          this.convertUTCToDate(data.cruizestartdate * 1000)
        ),
        cloudlease: this.GetTimestamp(
          this.convertUTCToDate(data.cloudleasestartdate * 1000)
        ),
        workload: this.GetTimestamp(
          this.convertUTCToDate(data.workloadstartdate * 1000)
        ),
        dockeronhost: this.GetTimestamp(
          this.convertUTCToDate(data.dockeronhoststartdate * 1000)
        ),
      },
      {
        rowTitle: "End Date",
        discover: this.GetTimestamp(
          this.convertUTCToDate(data.discoverenddate * 1000)
        ),
        transform: this.GetTimestamp(
          this.convertUTCToDate(data.transformenddate * 1000)
        ),
        cruize: this.GetTimestamp(
          this.convertUTCToDate(data.cruizeenddate * 1000)
        ),
        cloudlease: this.GetTimestamp(
          this.convertUTCToDate(data.cloudleaseenddate * 1000)
        ),
        workload: this.GetTimestamp(
          this.convertUTCToDate(data.workloadenddate * 1000)
        ),
        dockeronhost: this.GetTimestamp(
          this.convertUTCToDate(data.dockeronhostenddate * 1000)
        ),
      },
    ];

    return (
      <Modal
        width="520"
        title="Benefits"
        centered
        visible={this.props.show}
        onCancel={this.props.onClose}
        footer={null}
      >
        <Table
          dataSource={new_dict}
          columns={columns}
          size="small"
          pagination={false}
        />
        <br />
        *Note : Start and End Dates are considered in GMT
      </Modal>
    );
  }
}

export default SubscriptionBenefitsTable;
