import React from "react";
import { Table } from "antd";

class CustomerTable extends React.Component{

    
    render(){
         
      var customerData=this.props.customer;
      var customerNameFilter=[],partnerNameFilter=[];

     
      customerData.map(customer => 
      customerNameFilter.push({text:customer.customername,value:customer.customername}));


      var uniquePartnerName = [...new Set(customerData.map(customer => customer.partnername))];
      uniquePartnerName.map(val => partnerNameFilter.push({text:val,value:val}))

       
          const columns = [
            {
              title: 'Customer Name',
              dataIndex: 'customername',
              key: 'customername',
	      sorter: (a, b) => a.customername.localeCompare(b.customername),
              sortDirections: ['ascend','descend'],
	      filters:customerNameFilter,
	      onFilter: (value, record) => record.customername === value,
            }
            ,
            {
                title: 'Partner Name',
                dataIndex: 'partnername',
                key: 'partnername',
	        sorter: (a, b) => a.partnername.localeCompare(b.partnername),
                sortDirections: ['ascend','descend'],
                filters: partnerNameFilter,
		onFilter: (value, record) => record.partnername === value,
            },
            {
              title: 'Comments',
              dataIndex: 'comments',
              key: 'comments',
              sorter: (a, b) => a.comments.localeCompare(b.comments),
              sortDirections: ['ascend','descend'], 
	   },
            {
              title: 'Created by',
              dataIndex: 'createdby',
              key: 'createdby',
	      sorter: (a, b) => a.createdby.localeCompare(b.createdby),
              sortDirections: ['ascend','descend'],
            },
            {
              title: 'Created On',
              dataIndex: 'createdon',
              key: 'createdon',
	      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
              sortDirections: ['ascend','descend'],	
            },
            
          ];
          
        return(
          <Table dataSource={this.props.customer} columns={columns} className="emptyTable" 
           className="emptyDescription"  pagination = {{ showSizeChanger: true, pageSizeOptions: ['10', '20', '30','40','50'],
	   total: this.props.customer.length, defaultPageSize:10,
          showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true }} />
        )
        }
}


export default CustomerTable;
