import { notification } from "antd";
import 'antd/es/notification/style/index.css';
export function checkError(e, logout){
    if(e.response.data["Error"] === "Invalid Token, Please send the login token")
        {
          notification["error"]({
            message: "Your Session is expired please Login again",
          });
          localStorage.removeItem("id_token");
          localStorage.removeItem("email");
          logout();
        }
        else if(e.response.data["Error"] === "No Token, Please send the login token")
        {
          notification["error"]({
            message: "Your not loggedin Please login",
          });
          localStorage.removeItem("id_token");
          localStorage.removeItem("email");
          logout();
        }
        else{
          notification["error"]({
            message: "You are not authorized to access, Please Check with Administrator",
          });
         // localStorage.removeItem("id_token");
         // localStorage.removeItem("email");
         // logout();
        }
}
