import React from "react";
import ProjectTable from "../../components/Projects/projectTable.js";
import { Button, Modal, notification } from "antd";
import axios from "axios";
import ProjectForm from "../../components/Projects/projectForm";
import getFormattedISTDateTimeFromTimestamp from "../../utils/formatTimestamp"
import { getAllProjects } from '../../utils/api';
import { checkError } from '../../utils/responseErrorHandling'

class Project extends React.Component {
  state = {
    formVisible: false,
    projectData: "",
  };

  setFormVisible(formVisible) {
    this.setState({ formVisible });
  }

  handleOk = (e) => {
    console.log(e);
    this.setFormVisible(false);
  };

  getAllprojects= () => {
  
   (async function getallprojects(that){
      try{
        const result = await getAllProjects();
        const new_projectsData=that.SetTimestamp(result)
        that.setState({projectData : new_projectsData})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);      


      }
    })(this);

}
 componentDidMount() {
   
   this.getAllprojects();
	      
  }
  SetTimestamp = (projects) =>{
    var val;
    projects.map((item) => (
    val=this.GetTimestamp(item.createdon),
    item.createdon=val
    ))

    return projects;
  }
  GetTimestamp = ( createdon ) => {
    const {
      day,
      monthName,
      year,
      hours,
      minutes,
      seconds,
    } = getFormattedISTDateTimeFromTimestamp(createdon);
    return (
      day+" "+monthName+" "+year+" "+hours+":"+minutes+":"+seconds
    );
  }; 
  
  handleCancel = () => {
   
    this.setFormVisible(false);
    
  };

  render() {
    return (
      <React.Fragment>
        <Button
          onClick={() => this.setFormVisible(true)}
          style={{ float: "right", width: 155 }}
        >
          Add Project
        </Button>
        <br />
        <br />
        <br />
        {this.state.formVisible ? (
          <ProjectForm
	   getAllprojects={this.getAllprojects}
	   projectData={this.state.projectData}
            logout={this.props.onLogout}
	    show={this.state.formVisible}
            handleCancel={this.handleCancel}
          />
        ) : null}
        <ProjectTable projects={this.state.projectData} />
      </React.Fragment>
    );
  }
}
export default Project;
