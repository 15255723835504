import React from "react";
import CustomerTable from "../../components/Customers/customerTable";
import { Button , notification } from "antd";
import axios from "axios";
import CustomerForm from "../../components/Customers/customerForm";
import getFormattedISTDateTimeFromTimestamp from "../../utils/formatTimestamp"
import { getAllCustomers } from '../../utils/api';
import { checkError } from '../../utils/responseErrorHandling'

class Customer extends React.Component {
  state = {
    formVisible: false,
    customerData: [],
  };

  setFormVisible(formVisible) {
    this.setState({ formVisible });
  }

  handleCancel= () => {
    this.setFormVisible(false);
  }
 getallcustomers=()=>{
	(async function getAllcustomers(that){
      try{
        const result = await getAllCustomers();
        const new_customersData=that.SetTimestamp(result)
        that.setState({customerData : new_customersData})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);
      }
    })(this);

}
  
  componentDidMount(){
     //console.log("before API call in customer")
	this.getallcustomers();
    }

  SetTimestamp = (customers) =>{
    var val;
    customers.map((item) => (
    val=this.GetTimestamp(item.createdon),
    item.createdon=val
    ))

    return customers;
  }
  GetTimestamp = ( createdon ) => {
    const {
      day,
      monthName,
      year,
      hours,
      minutes,
      seconds,
    } = getFormattedISTDateTimeFromTimestamp(createdon);
    return (
      day+" "+monthName+" "+year+" "+hours+":"+minutes+":"+seconds
    );
  }; 

  render() {
    return (
      <React.Fragment>
        <Button
          style={{ float: "right", width: 155 }}
          onClick={() => this.setFormVisible(true)}
        >
          Add Customer
        </Button>
        <br/><br/><br/>
        {this.state.formVisible? <CustomerForm 
	getallcustomers = {this.getallcustomers}
	customerData={this.state.customerData}
	show={this.state.formVisible} 
	logout={this.props.onLogout}
	handleCancel={this.handleCancel} />:null}
        <CustomerTable customer={this.state.customerData} />
      </React.Fragment>
    );
  }
}

export default Customer;
