"use strict";

var validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
var chconstants = require('./constants');

export default function validateDeploymentForm({
    customerName,
    projectUUID,
    deploymentName,
    deploymentType,
    comments,
    createdBy}) {
  
    const errors = {
      customerName: "",
      projectUUID: "",
      deploymentName: "",
      deploymentType: "",
      comments:"",
      createdBy: ""
    };
  
    var validNamePattern = /^[a-z0-9_-]+$/g;  
    if (!customerName) {
      errors.customerName = "Please Select Customer Name";
      console.log(errors.customerName);
    } 

    if (!projectUUID) {
        console.log(projectUUID)
      errors.projectUUID = "Please Select Project Name";
      console.log(errors.projectUUID);
    } 
    const result = validNamePattern.test(deploymentName);
    if (!deploymentName) {
      console.log(deploymentName)
      errors.deploymentName = "Please Enter Deployment Name";
      console.log(errors.deploymentName);
    } 
    else if (result === false || deploymentName.length>100 ) {
      errors.deploymentName = "Invalid Deployment Name (Accepted characters are: a-z, 0-9, - , _ ,and Max 100 Characters)";
      console.log(errors.deploymentName);
    }
  
    if(!deploymentType)
    {
      console.log(deploymentType)
      errors.deploymentType = "Please Select Deployment Type "
      console.log(errors.deploymentType)
    }
    if (!comments) {
      errors.comments = "Please Enter Comments";
      console.log(errors.comments)
    }
    // else if(parseInt(deploymentType) !== chconstants.DEPLOYMENT_APPLIANCE && parseInt(deploymentType) !== chconstants.DEPLOYMENT_SAAS){
    //   console.log(deploymentType)
    //   errors.deploymentType = "Invalid Deployment Type"
    //   console.log(errors.deploymentType)
    // }
  
  
    return errors;
  }
