
export default function getFormattedISTDateTimeFromTimestamp(timestamp) {

  const MONTHNAMES3LETTERS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
    if (timestamp) {
      //console.log(timestamp)
      // date.toString(); // "Thu Jan 30 2020 12:25:19 GMT+0530 (India Standard Time)"
      const date = new Date(timestamp);
      const day = date.getDate();
      const monthName = MONTHNAMES3LETTERS[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return { day, monthName, year, hours, minutes, seconds };
    }
    return timestamp;
  }
  
  export function getUTCTimestampFromDateString(date){
    const [day,month,year] = date.split('-');

    const new_Date =  year+"-"+month+"-"+day;
    let utctimestamp = new Date(new_Date).getTime()
    const UTCTimestamp = utctimestamp/1000;
    
    return UTCTimestamp;
  
  }

  
  // exports.isLicenseCountValid = function(count, startdateutc, enddateutc){
  //   if(count >= 0){
  //     console.log('Input count ' + count + ' is valid');
  //     var startdate = new Date(startdateutc*1000).toUTCString();
  //     var enddate = new Date(enddateutc*1000).toUTCString();
  //     //var temp2 = new Date(enddateutc*1000);
  //     //var enddate = temp2.getDate() + '-' + temp2.getMonth() + '-' + temp2.getFullYear()
  //     if((count > 0) && (startdateutc < enddateutc)){
  //       console.log('Start date ' + startdateutc + ' (' + startdate + ') ' + ' and End date ' + enddateutc + ' (' + enddate + ') ' + ' is valid');
  //       return true;
  //     }else{
  //       console.log('Invalid Input : Start date ' + startdateutc + ' (' + startdate + ')' + ' is greater than End date ' + enddateutc + ' (' + enddate + ') ');
  //       return false;
  //     }
  //   }
  //   console.log('Input count ' + count + ' is not valid');
  //   return false;
  // };