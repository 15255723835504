/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Layout, Menu } from "antd";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "../../App.css";
import {
  SettingFilled,
  ProjectOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  CarryOutOutlined,
  BuildOutlined,
} from "@ant-design/icons";
import logo from "../../common/assets/images/CloudHedge-logo.png";

import Customer from "../containers/Customers/Customer";
import Project from "../containers/Projects/Project";
import Deployment from "../containers/Deployment/Deployment";
import Subscription from "../containers/Subscriptions/Subscription";
import Setting from "../containers/Settings/Setting";
import JenkinsBuild from "../containers/JenkinsBuild/JenkinsBuild";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const { Header, Footer, Sider, Content } = Layout;

const Dashboard = () => {
  const { instance } = useMsal();

  useEffect(() => {
    if (history) {
      if (history.location.pathname === "/") {
        history.push("/customer");
      }
    }
  }, []);

  const handleLogout = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/",
    });
  };

  return (
    <Router history={history}>
      <Layout>
        <Header style={{ background: "#fff" }}>
          <img
            src={logo}
            alt="Cloudhedge logo"
            style={{ blockSize: "70%" }}
          ></img>
          <a onClick={() => handleLogout()} style={{ float: "right" }}>
            Logout
          </a>
        </Header>
        <Layout>
          <Sider style={{ background: "gray" }}>
            <Menu
              defaultSelectedKeys={["1"]}
              style={{ height: "100%", borderRight: 0 }}
            >
              <Menu.Item key="1">
                <UserOutlined />
                Customer <Link to="/customer" />
              </Menu.Item>
              <Menu.Item key="2">
                <ProjectOutlined />
                Projects <Link to="/project" />
              </Menu.Item>
              <Menu.Item key="3">
                <DeploymentUnitOutlined />
                Deployment <Link to="/deployment" />
              </Menu.Item>
              <Menu.Item key="4">
                <BuildOutlined />
                Jenkins Build <Link to="/jenkinsbuild" />
              </Menu.Item>

              <Menu.Item key="5">
                <CarryOutOutlined /> Subscriptions <Link to="/subscription" />
              </Menu.Item>
              <Menu.Item key="6">
                <SettingFilled />
                Settings <Link to="/setting" />
              </Menu.Item>
            </Menu>
          </Sider>

          <Content style={{ padding: "0 30px", height: "100%" }}>
            <br />
            <div style={{ background: "#fff", padding: 24, minHeight: 500 }}>
              <Route
                path="/customer"
                component={() => <Customer logout={handleLogout} />}
              />
              <Route
                path="/project"
                component={() => <Project logout={handleLogout} />}
              />
              <Route
                path="/deployment"
                component={() => <Deployment logout={handleLogout} />}
              />
              <Route
                path="/subscription"
                component={() => <Subscription logout={handleLogout} />}
              />
              <Route
                path="/setting"
                component={() => <Setting logout={handleLogout} />}
              />
              <Route
                path="/jenkinsbuild"
                component={() => <JenkinsBuild logout={handleLogout} />}
              />
              <Route
                exact
                path="/"
                component={() => <Customer logout={handleLogout} />}
              />
            </div>
          </Content>
        </Layout>

        <Footer style={{ textAlign: "center", height: 80 }}>
          CloudHedge Technologies. All Rights Reserved.
        </Footer>
      </Layout>
    </Router>
  );
};

export default Dashboard;
