import React from "react";
import { Modal ,  Input , notification} from "antd";
import validateCustomerForm from "../../utils/validateCustomerForm.js";
import { addCustomer, getUserEmail } from '../../utils/api';
import { checkError } from '../../utils/responseErrorHandling'

const { TextArea } = Input;

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleok = this.handleok.bind(this);
  }
  state = {
    customerName: "",
    partnerName: "",
    comments: "",
    createdBy: "",
    errors: {
      customerNameError:"",
      customerName: "",
      partnerName: "",
      comments: "",
      createdBy: "",
    },
  };
 
 isNameExist = (name) => {
	if(name.length>=3)
    {
      var nameExist=false;
      this.props.customerData.map((customer) => 
      customer.customername === name && (nameExist=true))
      if(nameExist) {
        this.state.errors.customerNameError = 
        "Customer Name already exist,Please enter different name"
        }
        else{
          this.state.errors.customerNameError = ""
        }
     }
      else if(name.length<3 && this.state.errors.customerNameError)
      {
        this.state.errors.customerNameError = ""
      }
  }

  handleok = (event) => {
    event.preventDefault();
    const out_errors = validateCustomerForm(this.state);
    if(this.state.errors.customerNameError) 
      out_errors.customerNameError = this.state.errors.customerNameError;

    this.setState({errors: out_errors },this.validateForm);
    
  };
  handleChange(e) {
     //console.log(e.target.value)
     if(e.target.name === "customerName")
       this.isNameExist(e.target.value);

    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }

  validateForm = () => {
    let valid = true;
    Object.values(this.state.errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    if(valid)
    {
      const payload = {
        customerName: this.state.customerName,
        partnerName: this.state.partnerName,
        comments: this.state.comments,
        createdBy: getUserEmail()
      };

      (async function addcustomer(that){
        try{
          const result = await addCustomer(payload);
          if(result.Success)
          {
            notification['success']({
              message: 'Customer added Successfully'
            });
	   that.props.getallcustomers();
          }
          else if(result.Error)
          {
            notification['error']({
              message: 'Customer Name already exist',
              description:
                'Please Enter different Customer Name',
            })
          }
        } catch(e){
          console.error(e);
          checkError(e,that.props.logout);
        }
      })(this);


        this.props.handleCancel() 
    }
  };

  render() {
    return (
      <Modal
        title="Add Customer"
        centered
        visible={this.props.show}
        onOk={this.handleok}
        onCancel={this.props.handleCancel}
        okText="Add Customer"
      >
        <p>
          <label>Customer Name</label>  
          <Input placeholder="Enter Customer Name"
            type="text"
            value={this.state.customerName}
            name="customerName"
            onChange={(e) => this.handleChange(e)}
          ></Input>
          <span className="text-danger">{this.state.errors.customerName}</span>
          <br/>
          <span className="text-danger">{this.state.errors.customerNameError}</span>

        </p>
        <p>
          <label>Partner Name</label>
          <Input placeholder="Enter Partner Name"
            type="text"
            value={this.state.partnerName}
            name="partnerName"
            onChange={(e) => this.handleChange(e)}
          ></Input>
          <span className="text-danger">{this.state.errors.partnerName}</span>
        </p>
        <p>
          <label>Comments</label>
          <TextArea rows={2}  placeholder="Enter Comment"
            type="text"
            value={this.state.comments}
            name="comments"
            onChange={(e) => this.handleChange(e)}
          ></TextArea>
          <span className="text-danger">{this.state.errors.comments}</span>
        </p>
      </Modal>
    );
  }
}

export default CustomerForm;

