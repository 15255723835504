/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: "1f2318b1-06b5-4fa4-8460-012d1a5929e4",
    authority:
      "https://login.microsoftonline.com/de223b6c-4298-46b6-addc-fc7199cd534e",
    redirectUri: "https://subscription.cloudhedge.io",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here the endpoints and scopes for the web API you would like to use.
export const apiConfig = {
    scopes: ["api://2fff5bae-1b11-4033-9429-9a1610b640e3/access_as_user"]
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["openid", "profile"],
};

