import React from "react";
import { Modal, Select, Input, notification } from "antd";
import validateProjectForm from "../../utils/validateProjectForm.js";
import { getAllCustomers, addProject, getUserEmail } from '../../utils/api';
import { checkError } from '../../utils/responseErrorHandling'

const { Option } = Select;
const { TextArea } = Input;

class ProjectForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleok = this.handleok.bind(this);
  }
  state = {
    customerName: "",
    projectName: "",
    comments: "",
    createdBy: "",
    customerData: [""],
    errors: {
      projectNameError:"",	
      customerName: "",
      projectName: "",
      comments: "",
      createdBy: "",
    },
  };

  componentDidMount() {
    
    (async function getAllcustomers(that){
      try{
        const result = await getAllCustomers();
        that.setState({customerData : result})
      } catch(e){
        console.error(e);
       checkError(e,that.props.logout); 
	}
    })(this);
  }

  handleok = () => {
    const out_errors = validateProjectForm(this.state);
    if(this.state.errors.projectNameError) 
      out_errors.projectNameError = this.state.errors.projectNameError;

   
    this.setState({errors: out_errors },this.validateForm);
  };
  
  validateForm = () => {
    let valid = true;
    Object.values(this.state.errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    if(valid)
    {
      const payload = {
        customerName: this.state.customerName,
        projectName: this.state.projectName,
        comments: this.state.comments,
        createdBy: getUserEmail()
      };
      (async function addproject(that){
        try{
          const result = await addProject(payload);
          if(result.Success)
          {
	    that.props.getAllprojects();
            notification['success']({
              message: 'Project added Successfully'
            });
          }
          else if(result.Error)
          {
            notification['error']({
              message: 'Project Name already exist',
              description:
                'Please Enter different Project Name',
            })
          }
        } catch(e){
          console.error(e);
          checkError(e,that.props.logout); 
        }
      })(this);
  
      this.props.handleCancel();
    }
  };
  
  isNameExist = (name) => {
   
   if(name.length>=3)
    {

    var nameExist=false;
    this.props.projectData.map((project) => { 

    if(project.customername === this.state.customerName && project.projectname === name){
      nameExist=true
     }
    
    })

    if(nameExist) {
      this.state.errors.projectNameError = 
      "Project Name already exist,Please enter different name"
      }
      else{
        this.state.errors.projectNameError = ""
      }
   }
  else if(name.length<3 && this.state.errors.projectNameError)
  {
    this.state.errors.projectNameError = ""
  }  
}  
  
  handleChange(e) {
    if(e.target.name === "projectName")
      this.isNameExist(e.target.value);
 
   const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }
  handleCustomerName(value) {
    //const value = e.target.value;
    this.setState({customerName: value});
  }
  SelectBox = () => {
    return(
        <Select 
            name="customerName"
            onChange={(value) => this.handleCustomerName(value)}
            value={this.state.customerName}
            style={{ width: 240 }}
          >
            {this.state.customerData.map((item) => (
              <Option key={item.customername} value={item.customername}>
                {item.customername}
              </Option>
            ))}
          </Select>)
  }

  render() {
    return (
      <Modal
        title="Add Project"
        centered
        visible={this.props.show}
        onOk={this.handleok}
        onCancel={this.props.handleCancel}
        okText="Add Project"
      >
        <p>
          <label>Customer Name</label>  
          <span>        </span><br/>
          <this.SelectBox/><br/>
          <span className="text-danger">{this.state.errors.customerName}</span>
        </p>
        <p>
          <label>Project Name</label>
          <Input placeholder="Enter Project Name"
            type="text"
            value={this.state.projectName}
            name="projectName"
            onChange={(e) => this.handleChange(e)}
          ></Input>
           <span className="text-danger">{this.state.errors.projectName}</span><br/>
           <span className="text-danger">{this.state.errors.projectNameError}</span>

        </p>
        <p>
          <label>Comments</label>
          <TextArea rows={2}  placeholder="Enter Comment"
            type="text"
            value={this.state.comments}
            name="comments"
            onChange={(e) => this.handleChange(e)}
          ></TextArea>
          <span className="text-danger">{this.state.errors.comments}</span>
        </p>
      </Modal>
    );
  }
}

export default ProjectForm;
