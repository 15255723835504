import React from "react";
import { Table } from "antd";

class BuildTable extends React.Component{

    state={
    buildNameFilter:[],
    subTypeFilter:[],
    customerNameFilter:[],
    projectNameFilter:[],
    deploymentNameFilter:[]
    }
  
   
    static getDerivedStateFromProps(props)
    {
    
      if(props.builds)
      {
      var buildsData=props.builds;
      var uniqueBuildNames = [...new Set(buildsData.map(build => build.buildname))];
      var buildNames = uniqueBuildNames.map(val => ({text:val,value:val}))
  
      var subNames = [...new Set(buildsData.map(build => build.subscriptiontype))];
      var subTypes = subNames.map(val => ({text:val,value:val}))
 
      var uniqueCustomerNames = [...new Set(buildsData.map(build => build.customername))];
      var customerNames=uniqueCustomerNames.map(val => ({text:val,value:val}))


      var uniqueProjectNames = [...new Set(buildsData.map(build => build.projectname))];
      var projectNames=uniqueProjectNames.map(val => ({text:val,value:val}))

      var uniqueDeploymentNames = [...new Set(buildsData.map(build => build.deploymentname))];
      var deploymentNames=uniqueDeploymentNames.map(val => ({text:val,value:val}))
     


      return {
        buildNameFilter:buildNames,
        subTypeFilter:subTypes,
        customerNameFilter:customerNames,
        projectNameFilter:projectNames,
        deploymentNameFilter:deploymentNames,
      };
      }
  
      return null;
    }
 
    render(){
          
          const columns = [
	    {
              title: 'Customer Name',
              dataIndex: 'customername',
              key: 'customername',
              sorter: (a, b) => a.customername.localeCompare(b.customername),
              sortDirections: ['descend', 'ascend'],
              onFilter: (value, record) => record.customername === value,
              filters: this.state.customerNameFilter,
	       width: 120
            },
            {
              title: 'Project Name',
              dataIndex: 'projectname',
              key: 'projectname',
              sorter: (a, b) => a.projectname.localeCompare(b.projectname),
              sortDirections: ['descend', 'ascend'],
              onFilter: (value, record) => record.projectname  === value,
              filters: this.state.projectNameFilter,
	       width: 130
            },
            {
                title: 'Deployment Name',
                dataIndex: 'deploymentname',
                key: 'deploymentname',
                sorter: (a, b) => a.deploymentname.localeCompare(b.deploymentname),
                sortDirections: ['descend', 'ascend'],
                onFilter: (value, record) => record.deploymentname === value,
                filters: this.state.deploymentNameFilter,
	         width: 130
              },
            {
              title: 'Build Name',
              dataIndex: 'buildname',
              key: 'buildname',
	      sorter: (a, b) => a.buildname.localeCompare(b.buildname),
              sortDirections: ['ascend','descend'],
	      onFilter: (value, record) => record.buildname === value,
              filters: this.state.buildNameFilter,
	       width: 130
            }
            ,
            {
                title: 'Subscription Type',
                dataIndex: 'subscriptiontype',
                key: 'subscriptiontype',
	        sorter: (a, b) => a.subscriptiontype.localeCompare(b.subscriptiontype),
                sortDirections: ['ascend','descend'],
		onFilter: (value, record) => record.subscriptiontype === value,
                filters: this.state.subTypeFilter,
	        width:150,
            },
            {
                title: 'Branch',
                dataIndex: 'branch',
                key: 'branch',
	        sorter: (a, b) => a.branch.localeCompare(b.branch),
                sortDirections: ['ascend','descend'],
            },
            {
                title: 'Tag',
                dataIndex: 'tag',
                key: 'tag',
	        sorter: (a, b) => a.tag.localeCompare(b.tag),
                sortDirections: ['ascend','descend'],
	        width: 100,
            },
            {
                title: 'Form Factor',
                dataIndex: 'formfactor',
                key: 'formfactor',
	        sorter: (a, b) => a.formfactor.localeCompare(b.formfactor),
                sortDirections: ['ascend','descend'],
	         width: 150
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
	        sorter: (a, b) => a.email.localeCompare(b.email),
                sortDirections: ['ascend','descend'],
		ellipsis: true,
            },
            {
                title: 'Build Status',
                dataIndex: 'jenkinsbuildstatus',
                key: 'jenkinsbuildstatus',
		sorter: (a, b) => a.jenkinsbuildstatus.localeCompare(b.jenkinsbuildstatus),
                sortDirections: ['ascend','descend'],
		ellipsis: true,
            },
            {
              title: 'Comments',
              dataIndex: 'jbldcomments',
              key: 'jbldcomments',
	      sorter: (a, b) => a.jbldcomments.localeCompare(b.jbldcomments),
              sortDirections: ['ascend','descend'],
	      ellipsis: true,
            },
            {
              title: 'Created by',
              dataIndex: 'createdby',
              key: 'createdby',
	      sorter: (a, b) => a.createdby.localeCompare(b.createdby),
              sortDirections: ['ascend','descend'],
		ellipsis: true,
            },
            {
              title: 'Created On',
              dataIndex: 'createdon',
              key: 'createdon',
	      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
              sortDirections: ['ascend','descend'],
            },
	    {
              title: 'Get Build Details',
              dataIndex: 'download',
              key: 'download',
            }
            
          ];
         
        return(
          <Table dataSource={this.props.builds} columns={columns} size="small" scroll={{ x: 1500 }} className="emptyDescription"
	    pagination = {{ showSizeChanger: true, pageSizeOptions: ['10', '20', '30','40','50'],
          total: this.props.builds.length, 
         showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
         showQuickJumper: true }}  />
        )
        }
}


export default BuildTable;
