var validEmailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

export default function validateAddSubscriptionForm({
  customerName,
  projectName,
  buildName,
  deploymentUUID,
  subscriptionType,
  email,
  branch,
  tag,
  formFactor,
  comments,
}) {
  const errors = {
    customerName: "",
    projectName: "",
    deploymentUUID: "",
    buildName: "",
    subscriptionType: "",
    email: "",
    branch: "",
    tag: "",
    formFactor: "",
    comments: "",
  };

  var validNamePattern = /^[a-z0-9_-]+$/;

  if (!customerName) {
    errors.customerName = "Please Select Customer Name";
  }

  if (!projectName) {
    errors.projectName = "Please Select Project Name";
  }
  if (!deploymentUUID) {
    errors.deploymentUUID = "Please Select Deployment Name";
  }

  if (!buildName) {
    console.log(buildName);
    errors.buildName = "Please Enter Build Name";
  } else if (!buildName.match(validNamePattern) || buildName.length>100) {
    errors.buildName = " Invalid Build Name (Accepted characters are: a-z, 0-9, - , _ ,and Max 100 Characters)";
    console.log(errors.buildName);
  }

  if (!subscriptionType) {
    console.log(subscriptionType);
    errors.subscriptionType = "Please Select Subscription Type ";
    console.log(errors.subscriptionType);
  }


  if (!branch) {
    errors.branch = "Please Select Branch";
  }

  if (!tag) {
    errors.tag = "Please Select Tag";
  }


  if (!formFactor) {
    errors.formFactor = "Please Select Form Factor";
  }

  if (!email) {
    errors.email = "Please Enter Email";
  } else if (!email.match(validEmailPattern)) {
    errors.email = "Invalid Email";
  }

  if (!comments) {
    errors.comments = "Please Enter Comments";
    console.log(errors.comments);
  }

  return errors;
}

