import React from "react";
import { Input, Select } from "antd";
import { Row, Col } from "antd";

var chconstants = require("../../utils/constants");
const { TextArea } = Input;
const { Option } = Select;

class SubscriptionForm extends React.Component {
  render() {
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <label>Customer Name</label>
            {this.props.isTrial ? (
              <Select
                value={this.props.states.customerName}
                defaultValue="cloudhedge"
                style={{ width: 300 }}
                disabled
              >
                <Option value="cloudhedge">cloudhedge</Option>
              </Select>
            ) : (
              <Select
                name="customerName"
                onChange={(value) =>
                  this.props.handleSelectValues(value, "customerName")
                }
                style={{ width: 300 }}
                disabled={this.props.isTrial ? true : false}
                defaultValue={this.props.isTrial ? "tcs" : ""}
              >
                {this.props.states.customerData.map((item) => (
                  <Option key={item.customername} value={item.customername}>
                    {item.customername}
                  </Option>
                ))}
              </Select>
            )}

            <br />
            <span className="text-danger">
              {this.props.states.errors.customerName}
            </span>
          </Col>
          <Col span={8}>
            <label>Project Name</label>
            {this.props.isTrial ? (
              <Select defaultValue="production" style={{ width: 300 }} disabled>
                <Option value="production">production</Option>
              </Select>
            ) : (
              <Select
                value={this.props.states.projectName}
                name="projectName"
                onChange={(value) =>
                  this.props.handleSelectValues(value, "projectName")
                }
                style={{ width: 300 }}
                disabled={this.props.isTrial ? true : false}
                defaultValue={this.props.isTrial ? "production" : ""}
              >
                {this.props.states.projectData.map((item) =>
                  item.customername === this.props.states.customerName ? (
                    <Option key={item.projectuuid} value={item.projectname}>
                      {item.projectname}
                    </Option>
                  ) : (
                    " "
                  )
                )}
              </Select>
            )}
            <br />
            <span className="text-danger">
              {this.props.states.errors.projectName}
            </span>
          </Col>
          <Col span={8}>
            <label>Deployment Name</label>
            {this.props.isTrial ? (
              <Select
                defaultValue="app-deployment"
                style={{ width: 300 }}
                disabled
              >
                <Option value="app-deployment">app-deployment</Option>
              </Select>
            ) : (
              <Select
                value={this.props.states.deploymentUUID}
                name="deploymentUUID"
                onChange={(value) =>
                  this.props.handleSelectValues(value, "deploymentUUID")
                }
                style={{ width: 300 }}
                disabled={this.props.isTrial ? true : false}
                defaultValue={this.props.isTrial ? "app-deployment" : ""}
              >
                {this.props.states.deploymentData.map((item) =>
                  item.projectname === this.props.states.projectName &&
                  item.customername === this.props.states.customerName ? (
                    <Option
                      key={item.deploymentuuid}
                      value={item.deploymentuuid}
                    >
                      {item.deploymentname}
                    </Option>
                  ) : (
                    " "
                  )
                )}
              </Select>
            )}
            <br />
            <span className="text-danger">
              {this.props.states.errors.deploymentUUID}
            </span>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {this.props.isTrial ? (
            ""
          ) : (
            <Col span={8}>
              <label>Build Name</label>

              <Select
                value={this.props.states.buildUUID}
                name="buildUUID"
                onChange={(value) =>
                  this.props.handlebuildUUID(value, "buildUUID")
                }
                style={{ width: 300 }}
              >
                {this.props.states.buildData.map((item) =>
                  item.deploymentuuid === this.props.states.deploymentUUID ? (
                    <Option key={item.builduuid} value={item.builduuid}>
                      {item.buildname}
                    </Option>
                  ) : (
                    " "
                  )
                )}
              </Select>
              <br />
              <span className="text-danger">
                {this.props.states.errors.buildUUID}
              </span>
            </Col>
          )}
          <Col span={8}>
            <label>Subscription Type</label>

            <Select
              value={this.props.states.subscriptionType}
              name="subscriptionType"
              style={{ width: 300 }}
              disabled
            >
              <Option value={chconstants.SHARED}>Shared</Option>
              <Option value={chconstants.USER}>User</Option>
            </Select>
            <br />
            <span className="text-danger">
              {this.props.states.errors.subscriptionType}
            </span>
          </Col>
          <Col span={8}>
            <label>Subscription Name</label>
            <Input
              placeholder="Enter Subscription Name"
              type="text"
              value={this.props.states.subscriptionName}
              name="subscriptionName"
              onChange={(e) => this.props.handleChange(e)}
              style={{ width: 300 }}
            ></Input>
            <br />
            <span className="text-danger">
              {this.props.states.errors.subscriptionName}
            </span>
          </Col>
          {this.props.isTrial ? (
            <Col span={8}>
              <label>User Name</label>
              <Input
                placeholder="Enter User Name"
                type="text"
                value={this.props.states.userName}
                name="userName"
                onChange={(e) => this.props.handleChange(e)}
                style={{ width: 300 }}
              ></Input>
              <br />
              <span className="text-danger">
                {this.props.states.errors.userName}
              </span>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row gutter={[16, 16]}>
          {this.props.states.subscriptionType === chconstants.USER ? (
            <Col span={8}>
              <label>User Email</label>
              <br />
              <Input
                placeholder="Enter User Email"
                type="text"
                value={this.props.states.userEmail}
                name="userEmail"
                onChange={(e) => this.props.handleChange(e)}
                style={{ width: 300 }}
              ></Input>
              <br />
              <span className="text-danger">
                {this.props.states.errors.userEmail}
              </span>
            </Col>
          ) : (
            ""
          )}

          <Col span={16}>
            <label>Comments</label>
            <br />
            <TextArea
              rows={1}
              placeholder="Enter Comment"
              type="text"
              value={this.props.states.comments}
              name="comments"
              onChange={(e) => this.props.handleChange(e)}
              style={{ width: 630 }}
            ></TextArea>
            <br />
            <span className="text-danger">
              {this.props.states.errors.comments}
            </span>
            <br />
          </Col>
        </Row>
      </>
    );
  }
}

export default SubscriptionForm;
