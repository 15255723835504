"use strict";

var validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
var chconstants = require('./constants');
//var validateuuid = require('uuid-validate');

export default function validateProjectForm({
  customerName,
  projectName,
  comments,
  createdBy,
}) {
  const errors = {
    customerName: "",
    projectName: "",
    comments:"",
    createdBy: "",
  };
  const validNamePattern = /^[a-z0-9_-]+$/g;

  if (!customerName) {
    errors.customerName = "Please Select Customer Name";
    console.log(errors.customerName);
  } 
 const result = validNamePattern.test(projectName);
 console.log(result) 
// console.log(validNamePattern.test(projectName),projectName)
  if (!projectName) {
    errors.projectName = "Please Enter Project Name";
    console.log(errors.projectName);
  } 
  else if (result===false || projectName.length>100 ) {
    errors.projectName = "Invalid Project Name (Accepted characters are: a-z, 0-9, - , _ ,and Max 100 Characters)";
    console.log(errors.projectName);
  }
  if (!comments) {
    errors.comments = "Please Enter Comments";
    console.log(errors.comments)
  }

  // if (!createdBy) {
  //   errors.createdBy = "Please Enter Email ";
  //   console.log(errors.createdBy);
  // } 
  // else if (!createdBy.match(validEmailPattern) || createdBy.length > 100) {
  //   console.log("Input email is not valid");
  //   errors.createdBy = "Invalid Email";
  // }

  return errors;
}


