"use strict";

var validEmailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
var chconstants = require('./constants');

export default function validateAddSubscriptionForm({
    customerName,
    projectName,
    deploymentUUID,
    buildUUID,
    subscriptionName,
    subscriptionType,
    userEmail,
    userName,
    discoverLicenseCount,
    discoverStartDate,
    discoverEndDate,
    transformLicenseCount,
    transformStartDate,
    transformEndDate,
    cruizeLicenseCount,
    cruizeStartDate,
    cruizeEndDate,
    cloudleaseLicenseCount,
    cloudleaseStartDate,
    cloudleaseEndDate,
    workloadLicenseCount,
    workloadStartDate,
    workloadEndDate,
    dockeronhostLicenseCount,
    dockeronhostStartDate,
    dockeronhostEndDate,
    comments,
    createdBy},isTrialSubscription) {
   
    const errors = {
      customerName: "",
      projectName: "",
      deploymentUUID: "",
      subscriptionName:"",
      subscriptionType: "",
      userEmail: "",
      discoverLicenseCount:"",
      discoverStartDate:"",
      discoverEndDate:"",
      discoverDateError:"",
      transformLicenseCount:"",
      transformStartDate:"",
      transformEndDate:"",
      transformDateError:"",
      cruizeLicenseCount:"",
      cruizeStartDate:"",
      cruizeEndDate:"",
      cruizeDateError:"",
      cloudleaseLicenseCount:"",
      cloudleaseStartDate:"",
      cloudleaseEndDate:"",
      cloudleaseDateError:"",
      workloadLicenseCount:"",
      workloadStartDate:"",
      workloadEndDate:"",
      workloadDateError:"",
      dockeronhostLicenseCount:"",
      dockeronhostStartDate:"",
      dockeronhostEndDate:"",
      dockeronhostDateError:"",
      comments:"",
      createdBy: ""
    };
  
    var validNamePattern = /^[a-z0-9_-]+$/;
    var validUserNamePattern = /^[a-zA-Z0-9_ -]+$/;
  
    if (!customerName) {
      errors.customerName = "Please Select Customer Name";
    } 

    if (!projectName) {
      errors.projectName = "Please Select Project Name";
    } 
    if (!deploymentUUID) {
      errors.deploymentUUID = "Please Select Deployment Name";
    }

    if(!isTrialSubscription)
    {
      if(!buildUUID)
      {
        errors.buildUUID = "Please Select Build Name";
      }
      
    }
    if(isTrialSubscription)
    {
      if(!userName)
      {
        errors.userName = "Please Enter User Name"
      }
      else if(!(userName.match(validUserNamePattern)) || userName.length>100)
      {
	 errors.userName = "Invalid User Name (Accepted characters are: a-z, A-Z, 0-9, - , _ ,and Max 100 Characters)";
      }
    }

    const result = validNamePattern.test(subscriptionName)
    if (!subscriptionName) {
      console.log(subscriptionName)
      errors.subscriptionName = "Please Enter Subcription Name";
    } 
    else if (result === false || subscriptionName.length>100) {
      errors.subscriptionName = " Invalid Subcription Name (Accepted characters are: a-z, 0-9, - , _ ,and Max 100 Characters)";
      console.log(errors.subscriptionName);
    }
  

    if(subscriptionType === chconstants.USER)
    {
      if(!userEmail)
      {
        errors.userEmail="Please Enter User Email"
      }
      else if(!(userEmail.match(validEmailPattern)))
      {
        errors.userEmail="Invalid User Email"
      }
    }
    else if(subscriptionType === chconstants.SHARED)
    {
      if(userEmail)
      {
      if(!(userEmail.match(validEmailPattern)))
      {
        errors.userEmail="Invalid User Email"
      }
    }
    }

    if (!comments) {
      errors.comments = "Please Enter Comments";
      console.log(errors.comments)
    }
     
    if(discoverLicenseCount === 0 && transformLicenseCount === 0 && cruizeLicenseCount === 0 &&
      cloudleaseLicenseCount === 0 && workloadLicenseCount === 0 && dockeronhostLicenseCount === 0)
      {
        errors.licenseCount = "Please Enter atleast one License count greater than 0";
      }

    if(!discoverLicenseCount && discoverLicenseCount!=0)
    {
      errors.discoverLicenseCount="Required Count"
    }
    else if(discoverLicenseCount<0 )
    {
      errors.discoverLicenseCount="Invalid License Count"
    }
    if(!transformLicenseCount  && transformLicenseCount!=0 )
    {
      errors.transformLicenseCount="Required Count"
    }
    else if(transformLicenseCount<0)
    {
      errors.transformLicenseCount="Invalid License Count"
    }
    else if(transformLicenseCount>0 && (discoverLicenseCount)===0 )
    {
      errors.transformLicenseCount="Invalid License Count since Discover count is zero"
    }
    if(!cruizeLicenseCount  && cruizeLicenseCount!=0)
    {
      errors.cruizeLicenseCount="Required Count"
    }
    else if(cruizeLicenseCount<0)
    {
      errors.cruizeLicenseCount="Invalid License Count"
    }
    if(!cloudleaseLicenseCount  && cloudleaseLicenseCount!=0 )
    {
      errors.cloudleaseLicenseCount="Required Count"
    }
    else if(cloudleaseLicenseCount<0)
    {
      errors.cloudleaseLicenseCount="Invalid License Count"
    }
    if(!workloadLicenseCount  && workloadLicenseCount!=0)
    {
      errors.workloadLicenseCount="Required Count"
    }
    else if(workloadLicenseCount<0)
    {
      errors.workloadLicenseCount="Invalid License Count"
    }
    if(!dockeronhostLicenseCount  && dockeronhostLicenseCount!=0)
    {
      errors.dockeronhostLicenseCount="Required Count"
    }
    else if(dockeronhostLicenseCount<0)
    {
      errors.dockeronhostLicenseCount="Invalid DockerOnHost Count"
    }
    
    if(discoverStartDate && discoverEndDate)
    {
    if(discoverStartDate>=discoverEndDate)
    {
      console.log(discoverStartDate,discoverEndDate);
      errors.discoverDateError="Please Select Valid End Date"
    }
  }
  if(transformStartDate && transformEndDate)
  {
    if(transformStartDate>=transformEndDate)
    {
      errors.transformDateError="Please Select Valid End Date"
    }
  }
  if(cruizeStartDate && cruizeEndDate)
  {
    if(cruizeStartDate>=cruizeEndDate)
    {
      errors.cruizeDateError="Please Select Valid End Date"
    }
  }
  if(cloudleaseStartDate && cloudleaseEndDate)
  {
    if(cloudleaseStartDate>=cloudleaseEndDate)
    {
      errors.cloudleaseDateError="Please Select Valid End Date"
    }
  }
  if(workloadStartDate && workloadEndDate)
  {
    if(workloadStartDate>=workloadEndDate)
    {
      errors.workloadDateError="Please Select Valid End Date"
    }
  }
  if(dockeronhostStartDate && dockeronhostEndDate)
  {
    if(dockeronhostStartDate>=dockeronhostEndDate)
    {
      errors.dockeronhostDateError="Please Select Valid End Date"
    }
  }
   
    if(discoverLicenseCount>0 && !discoverStartDate)
    {
      errors.discoverStartDate="Required start Date"
    }
    if(transformLicenseCount>0 && !transformStartDate)
    {
      errors.transformStartDate="Required start Date"
    }
    if(cruizeLicenseCount>0 && !cruizeStartDate)
    {
      errors.cruizeStartDate="Required start Date"
    }if(cloudleaseLicenseCount>0 && !cloudleaseStartDate)
    {
      errors.cloudleaseStartDate="Required start Date"
    }if(workloadLicenseCount>0 && !workloadStartDate)
    {
      errors.workloadStartDate="Required start Date"
    }if(dockeronhostLicenseCount>0 && !dockeronhostStartDate)
    {
      errors.dockeronhostStartDate="Required start Date"
    }

    if(discoverLicenseCount>0 && !discoverEndDate)
    {
      errors.discoverEndDate="Required End Date"
    }
    if(transformLicenseCount>0 && !transformEndDate)
    {
      errors.transformEndDate="Required End Date"
    }if(cruizeLicenseCount>0 && !cruizeEndDate)
    {
      errors.cruizeEndDate="Required End Date"
    }if(cloudleaseLicenseCount>0 && !cloudleaseEndDate)
    {
      errors.cloudleaseEndDate="Required End Date"
    }if(workloadLicenseCount>0 && !workloadEndDate)
    {
      errors.workloadEndDate="Required End Date"
    }if(dockeronhostLicenseCount>0 && !dockeronhostEndDate)
    {
      errors.dockeronhostEndDate="Required End Date"
    }

    return errors;
  }
