import React from "react";
import { Modal, Input, notification, Select } from "antd";

class Popup extends React.Component {
  render() {
    return (
      <Modal
        title="Your Session is expired"
        centered
        visible={this.props.visible}
        onOk={this.props.getWithExpiry}
        okText="Login Again
      me"
      />
    );
  }
}

export default Popup;
