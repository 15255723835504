import React from "react";
import SettingTable from "../../components/Settings/settingTable";
import AddCHUser from "../../components/Settings/addCHUserForm";
import { Button, notification } from "antd";
import { getAllUsers } from "../../utils/api";
import getFormattedISTDateTimeFromTimestamp from "../../utils/formatTimestamp";
import { checkError } from "../../utils/responseErrorHandling";

var chconstants = require("../../utils/constants");

class Setting extends React.Component {
  state = {
    formVisible: false,
    usersData: [],
  };

  setFormVisible(formVisible) {
    this.setState({ formVisible });
  }

  handleCancel = () => {
    this.setFormVisible(false);
  };
  getalluser = () => {
    (async function getAllusers(that) {
      try {
        const result = await getAllUsers();
        const rawUsers = result;
        const userData = that.SetTimestamp(rawUsers);
        userData.map((user) =>
          user.role === chconstants.USERROLE
            ? (user.role = "Basic")
            : (user.role = "Admin")
        );
        that.setState({ usersData: userData });
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);
  };
  componentDidMount() {
    this.getalluser();
  }
  SetTimestamp = (users) => {
    var val;
    users.map(
      (item) => (
        (val = this.GetTimestamp(item.createdon)), (item.createdon = val)
      )
    );

    return users;
  };
  GetTimestamp = (createdon) => {
    //console.log("In get Timestamp",createdon)
    if (createdon) {
      const { day, monthName, year, hours, minutes, seconds } =
        getFormattedISTDateTimeFromTimestamp(createdon);
      return (
        day +
        " " +
        monthName +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    } else return "NA";
  };

  render() {
    return (
      <React.Fragment>
        <Button style={{ float: "right", width: 155 }}> Edit CH User </Button>
        <Button
          style={{ float: "right", width: 155 }}
          onClick={() => this.setFormVisible(true)}
        >
          Add CH User
        </Button>
        <br />
        <br />
        <br />
        {this.state.formVisible ? (
          <AddCHUser
            getalluser={this.getalluser}
            usersData={this.state.usersData}
            logout={this.props.onLogout}
            show={this.state.formVisible}
            handleCancel={this.handleCancel}
          />
        ) : null}
        <SettingTable usersData={this.state.usersData} />
      </React.Fragment>
    );
  }
}

export default Setting;
