import React from "react";
import { Table } from "antd";

class SettingTable extends React.Component{

   state={
    userNameFilter:[],
    rolesFilter:[]
    }
  
   
    static getDerivedStateFromProps(props)
    {
    
      if(props.usersData)
      {
      var users=props.usersData;
      // var uniqueUserNames = [...new Set(users.map(user => user.email))];
      // var userNames = uniqueUserNames.map(val => ({text:val,value:val}))
  
      var userNames=users.map(user => ({text:user.email,value:user.email}));


      var uniqueRoles = [...new Set(users.map(user => user.role))];
      var roles = uniqueRoles.map(val => ({text:val,value:val}))
  
      return {
        userNameFilter:userNames,
        rolesFilter:roles
      };
      }
  
      return null;
    }   
 
    render(){
          const columns = [
            {
              title: 'User Name',
              dataIndex: 'email',
              key: 'email',
	      sorter: (a, b) => a.email.localeCompare(b.email),
              sortDirections: ['ascend','descend'],
	      onFilter: (value, record) => record.email === value,
              filters: this.state.userNameFilter
            },
            {
              title: 'Role',
              dataIndex: 'role',
              key: 'role',
	      sorter: (a, b) => a.role.localeCompare(b.role),
              sortDirections: ['ascend','descend'],
	      onFilter: (value, record) => record.role === value,
              filters: this.state.rolesFilter
            },
            {
              title: 'Created by',
              dataIndex: 'createdby',
              key: 'createdby',
	      sorter: (a, b) => a.createdby.localeCompare(b.createdby),
              sortDirections: ['ascend','descend'],
            },
            {
              title: 'Created On',
              dataIndex: 'createdon',
              key: 'createdon',
	      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
              sortDirections: ['ascend','descend'],
            },
          ];
        return(
          
          <Table dataSource={this.props.usersData} columns={columns} className="emptyDescription" 
	    pagination = {{ showSizeChanger: true, pageSizeOptions: ['10', '20', '30','40','50'],
          total: this.props.usersData.length, defaultPageSize:10,
         showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
         showQuickJumper: true }} />
        )
        }
}

export default SettingTable;
