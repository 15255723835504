import React from "react";
import { Button, Tabs , Input} from 'antd';
import AddSubcription from '../../components/Subscriptions/addSubscription'
import DownloadSubscription from '../../components/Subscriptions/downloadSubscription'

const TabPane = Tabs.TabPane;
class Subscription extends React.Component{

    render(){
        return(
            <React.Fragment>
            {/* <Button style={{float:'right', width:165}}>Download Subscription</Button>
            <Button style={{float:'right', width:155}} onClick={() => this.setFormVisible(true)}
          >Add Subscription</Button> */}
           {/* {this.state.formVisible? <AddDeployementForm show={this.state.formVisible} handleCancel={this.handleCancel}/>:null}  */}
          
          <Tabs tabPosition='top' >
          <TabPane tab="Download Subscription" key="1">
            <DownloadSubscription logout={this.props.logout} ></DownloadSubscription>
          </TabPane>
          <TabPane tab="Add Subscription" key="2">
           <AddSubcription logout={this.props.logout} isTrial={false}/>
          </TabPane>
          <TabPane tab="Trial Subscription for app.cloudhedge.io" key="3">
          <AddSubcription logout={this.props.logout} isTrial={true}/>
          </TabPane>
       	  </Tabs>          
            </React.Fragment>
        )
        }
}

export default Subscription;
