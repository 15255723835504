import React from "react";
import DeploymentTable from "../../components/Deployments/deploymentTable";
import { Button, notification } from "antd";
import axios from "axios";
import DeploymentForm from "../../components/Deployments/deploymentForm";
import getFormattedISTDateTimeFromTimestamp from "../../utils/formatTimestamp"
import { getAllDeployments } from '../../utils/api';
import { checkError } from '../../utils/responseErrorHandling'

var chconstants = require('../../utils/constants');

class Deployment extends React.Component {
  state = {
    formVisible: false,
    projectData: "",
  };

  setFormVisible(formVisible) {
    this.setState({ formVisible });
  }

getalldeployments=()=>{
    (async function getAlldeployments(that){
      try{
        const result = await getAllDeployments();
        const new_deploymentsData=that.SetTimestamp(result)
        new_deploymentsData.map((deployment) => (
          (deployment.deploymenttype === chconstants.DEPLOYMENT_APPLIANCE? deployment.deploymenttype = "Appliance":
          deployment.deploymenttype = "SaaS")))

	that.setState({deploymentData : new_deploymentsData})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);

      }
    })(this);

}
  handleCancel = () => {
    this.setFormVisible(false);
    
  };

  componentDidMount() {
   this.getalldeployments();


    // axios
    //   .get("http://100.25.138.240:3000/chdeployment/getalldeployments")
    //   .then((response) => {
    //     const deployments = response.data;
    //     const new_deploymentsData = this.SetTimestamp(deployments);
    //     this.setState({ deploymentData: new_deploymentsData });
    //     //console.log(this.state.deploymentData);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

  }

  SetTimestamp = (deployments) =>{
    var val;
    deployments.map((item) => (
    val=this.GetTimestamp(item.createdon),
    item.createdon=val
    ))

    return deployments;
  }
  GetTimestamp = ( createdon ) => {
    const {
      day,
      monthName,
      year,
      hours,
      minutes,
      seconds,
    } = getFormattedISTDateTimeFromTimestamp(createdon);
    return (
      day+" "+monthName+" "+year+" "+hours+":"+minutes+":"+seconds
    );
  };

  render() {
    return (
      <React.Fragment>

        <Button
          onClick={() => this.setFormVisible(true)}
          style={{ float: "right", width: 155 }}
        >
          Add Deployment
        </Button>
        <br />
        <br />
        <br />
        {this.state.formVisible ? (
          <DeploymentForm
	    getalldeployments = {this.getalldeployments}
            deploymentData={this.state.deploymentData}
	    logout={this.props.onLogout}
            show={this.state.formVisible}
            handleCancel={this.handleCancel}
          />
        ) : null}

        <DeploymentTable deployments={this.state.deploymentData} />
      </React.Fragment>
    );
  }
}

export default Deployment;
