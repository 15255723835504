import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "antd";

import logo from "../../common/assets/images/CloudHedge-logo.png";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div class="bg-img">
      <div class="container">
        <img
          src={logo}
          alt="Cloudhedge logo"
          style={{ width: "250px", height: "100px" }}
        ></img>
        <div class="btn">
          <Button onClick={() => handleLogin()}>Sign in with Microsoft</Button>
        </div>
      </div>
    </div>
  );
};
