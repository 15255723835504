import React from "react";
import { Table } from "antd";

class DeploymentTable extends React.Component{

    
state={
    customerNameFilter:[],
    projectNameFilter:[],
    deploymentNameFilter:[]
    }

  static getDerivedStateFromProps(props)
  {
  
    if(props.deployments)
    {
      var deploymentData=props.deployments;
      
      var uniqueCustomerNames = [...new Set(deploymentData.map(deployment => deployment.customername))];
      var customerNames=uniqueCustomerNames.map(val => ({text:val,value:val}))


      var uniqueProjectNames = [...new Set(deploymentData.map(deployment => deployment.projectname))];
      var projectNames=uniqueProjectNames.map(val => ({text:val,value:val}))

      var uniqueDeploymentNames = [...new Set(deploymentData.map(deployment => deployment.deploymentname))];
      var deploymentNames=uniqueDeploymentNames.map(val => ({text:val,value:val}))
  

    return {
      customerNameFilter:customerNames,
      projectNameFilter:projectNames,
      deploymentNameFilter:deploymentNames
    };
    }

    return null;
  }

   render(){

        
	  const columns = [
            {
              title: 'Customer Name',
              dataIndex: 'customername',
              key: 'customername',
	      sorter: (a, b) => a.customername.localeCompare(b.customername),
              sortDirections: [ 'ascend','descend'],
	      onFilter: (value, record) => record.customername === value,
	      filters:this.state.customerNameFilter
            },
            {
              title: 'Project Name',
              dataIndex: 'projectname',
              key: 'projectname',
	      sorter: (a, b) => a.projectname.localeCompare(b.projectname),
              sortDirections: ['ascend','descend'],
	      onFilter: (value, record) => record.projectname  === value,
	      filters:this.state.projectNameFilter
            },
            {
                title: 'Deployment Name',
                dataIndex: 'deploymentname',
                key: 'deploymentname',
	        sorter: (a, b) => a.deploymentname.localeCompare(b.deploymentname),
                sortDirections: ['ascend','descend'],
	        onFilter: (value, record) => record.deploymentname === value,
	        filters:this.state.deploymentNameFilter
              },
	      {
                title: 'Deployment Type',
                dataIndex: 'deploymenttype',
                key: 'deploymenttype',
	        sorter: (a, b) => a.deploymenttype.localeCompare(b.deploymenttype),
                sortDirections: ['ascend','descend'],
              },
              {
                title: 'Comments',
                dataIndex: 'comments',
                key: 'comments',
	        sorter: (a, b) => a.comments.localeCompare(b.comments),
                sortDirections: ['ascend','descend'],
              },
            {
              title: 'Created by',
              dataIndex: 'createdby',
              key: 'createdby',
	      sorter: (a, b) => a.createdby.localeCompare(b.createdby),
              sortDirections: ['ascend','descend'],
            },
            {
              title: 'Created On',
              dataIndex: 'createdon',
              key: 'createdon',
	      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
              sortDirections: ['ascend','descend'],
            },

  ];
          
        return(
            <Table dataSource={this.props.deployments} columns={columns} className="emptyDescription" 
	      pagination = {{ showSizeChanger: true, pageSizeOptions: ['10', '20', '30','40','50'],
           total:this.props.deployments ?this.props.deployments.length : null, defaultPageSize:10,
          showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true }} />
        )
        }
}

export default DeploymentTable;
