import React from "react";
import {
  Modal,
  Select,
  Input,
  message,
  Table,
  Button,
  notification,
} from "antd";
import axios from "axios";
import DownloadSubscriptionTable from "../../components/Subscriptions/downloadSubscriptionTable";
import SubscriptionBenefitsTable from "../../components/Subscriptions/subscriptionBenefitsTable";
import getFormattedISTDateTimeFromTimestamp from "../../utils/formatTimestamp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  getAllCustomers,
  getAllProjects,
  getAllDeployments,
  getAllSubscriptionsPerDeployment,
  getSubscriptionLicense,
} from "../../utils/api";
import { checkError } from "../../utils/responseErrorHandling";
import { Row, Col } from "antd";

var chconstants = require("../../utils/constants");

const FileDownload = require("js-file-download");

const { Option } = Select;

class DownloadSubscription extends React.Component {
  state = {
    customerName: "",
    projectName: "",
    deploymentUUID: "",
    subscriptionData: [],
    customerData: [],
    projectData: [],
    deploymentData: [],
    openBenefits: false,
    benefit: {},
  };
  onMouseEnter = (benefits) => {
    this.setState({ openBenefits: true });
    this.setState({ benefit: benefits });
  };

  onMouseLeave = () => {
    this.setState({ openBenefits: false });
  };
  componentDidMount() {
    (async function getAllcustomers(that) {
      try {
        const result = await getAllCustomers();
        that.setState({ customerData: result });
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);

    (async function getAllprojects(that) {
      try {
        const result = await getAllProjects();
        that.setState({ projectData: result });
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);

    (async function getAlldeployments(that) {
      try {
        const result = await getAllDeployments();
        that.setState({ deploymentData: result });
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);
  }

  getsubscriptionData = () => {
    const payload = {
      deploymentUUID: this.state.deploymentUUID,
    };


    (async function getsubscriptions(that) {
      try {
        const result = await getAllSubscriptionsPerDeployment(payload);
        const rawSubscriptions = result;
        const subscriptionData = that.SetTimestamp(rawSubscriptions);
        subscriptionData.map(
          (benefit) => (
            (benefit.benefits = (
              <a onClick={() => that.onMouseEnter(benefit)}>View Benefits</a>
            )),
            !benefit.useremail ? (benefit.useremail = "NA") : benefit.useremail
          )
        );

        subscriptionData.map(
          (subscription) => (
            subscription.subscriptiontype === chconstants.SHARED
              ? (subscription.subscriptiontype = "Shared")
              : (subscription.subscriptiontype = "User"),
            (subscription["download"] = (
              <Button
                onClick={() =>
                  that.downloadLicense(subscription.subscriptionuuid)
                }
              >
                <FontAwesomeIcon icon={faDownload} />{" "}
              </Button>
            ))
          )
        );

        that.setState({ subscriptionData: subscriptionData });
      } catch (e) {
        console.error(e);
        checkError(e, that.props.logout);
      }
    })(this);
  };
  downloadLicense = (successData) => {
    const subscriptionID = successData;
    const payload = {
      subscriptionUUID: subscriptionID,
    };

    (async function getsubscriptionlicense(that) {
      try {
        const result = await getSubscriptionLicense(payload);

        if (result.Success)
          FileDownload(result.Success, `${subscriptionID}.lic`);

        if (result.Success)
          notification["success"]({
            message: "License Downloaded Successfully",
          });
      } catch (e) {
        console.error(e);
        checkError(e, that.props.logout);
      }
    })(this);
  };

  SetTimestamp = (customers) => {
    var val;
    customers.map(
      (item) => (
        (val = this.GetTimestamp(item.createdon)), (item.createdon = val)
      )
    );

    return customers;
  };

  GetTimestamp = (createdon) => {
    const { day, monthName, year, hours, minutes, seconds } =
      getFormattedISTDateTimeFromTimestamp(createdon);
    return (
      day +
      " " +
      monthName +
      " " +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  handleChange(e) {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }

  handleCustomerName(value) {
    this.setState({ customerName: value });
  }
  handleProjectName(value) {
    this.setState({ projectName: value });
  }
  handleDeploymentUUID(value) {
    this.setState({ deploymentUUID: value }, this.getsubscriptionData);
  }

  render() {
    return (
      <form onSubmit={this.handleok}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <label>Customer Name</label>
            <Select
              placeholder="please select customer name"
              name="customerName"
              onChange={(value) => this.handleCustomerName(value)}
              value={this.state.customerName}
              style={{ width: 300 }}
            >
              {this.state.customerData.map((item) => (
                <Option key={item.customername} value={item.customername}>
                  {item.customername}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <label>Project Name</label>
            <Select
              value={this.state.projectName}
              name="projectName"
              onChange={(value) => this.handleProjectName(value)}
              style={{ width: 300 }}
            >
              {this.state.projectData.map((item) =>
                item.customername === this.state.customerName ? (
                  <Option key={item.projectuuid} value={item.projectname}>
                    {item.projectname}
                  </Option>
                ) : (
                  " "
                )
              )}
            </Select>
          </Col>
          <Col span={8}>
            <label>Deployment Name</label>
            <Select
              value={this.state.deploymentUUID}
              name="deploymentUUID"
              onChange={(value) => this.handleDeploymentUUID(value)}
              style={{ width: 300 }}
            >
              {this.state.deploymentData.map((item) =>
                item.projectname === this.state.projectName &&
                item.customername === this.state.customerName ? (
                  <Option key={item.deploymentuuid} value={item.deploymentuuid}>
                    {item.deploymentname}
                  </Option>
                ) : (
                  " "
                )
              )}
            </Select>
          </Col>
        </Row>
        <SubscriptionBenefitsTable
          benefitsTable={this.state.benefit}
          onClose={this.onMouseLeave}
          show={this.state.openBenefits}
        />
        <DownloadSubscriptionTable
          subscriptions={this.state.subscriptionData}
        />
      </form>
    );
  }
}

export default DownloadSubscription;
