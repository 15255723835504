"use strict";

var validEmailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
var chconstants = require('./constants');

export default function validateDeploymentForm({
    userName,
    createdBy,
    userRole}) {
  
    const errors = {
        userName: "",
        userRole:"",
        createdBy: "",
    };
    var validNamePattern = /^[a-z0-9_-]+$/g;

    const result = validNamePattern.test(userName);
    if (!userName) {
      errors.userName = "Please Enter User Name";
      console.log(errors.userName);
    } 
    else if(!(userName.match(validEmailPattern)) )
    {
      errors.userName = "Invalid User Name";
      console.log(errors.userName);
    }

    if (!userRole) {
      errors.userRole = "Please Select User Role";
      console.log(errors.userRole);
    } 
   
    return errors;
  }
