import React from "react";
import { Modal, Input, notification, Select } from "antd";
import validateAddUserForm from "../../utils/validateAddUserForm.js";
import { addUser, getUserEmail } from "../../utils/api";
import { checkError } from "../../utils/responseErrorHandling";

var chconstants = require("../../utils/constants");

const { Option } = Select;

class AddCHUser extends React.Component {
  state = {
    userName: "",
    createdBy: "",
    userRole: "",
    errors: {
      userNameError: "",
      userName: "",
      userRole: "",
      createdBy: "",
    },
  };

  handleok = (event) => {
    //event.preventDefault();
    const out_errors = validateAddUserForm(this.state);
    if (this.state.errors.userNameError)
      out_errors.userNameError = this.state.errors.userNameError;

    this.setState({ errors: out_errors }, this.validateForm);
  };

  isNameExist = (name) => {
    if (name.length >= 3) {
      var nameExist = false;
      this.props.usersData.map(
        (user) => user.email === name && (nameExist = true)
      );
      if (nameExist) {
        this.state.errors.userNameError =
          "User Name already exist, Please enter different name";
      } else {
        this.state.errors.userNameError = "";
      }
    } else if (name.length < 3 && this.state.errors.userNameError) {
      this.state.errors.userNameError = "";
    }
  };

  handleChange(e) {
    if (e.target.name === "userName") this.isNameExist(e.target.value);

    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }
  handleUserRole(value) {
    this.setState({ userRole: value });
  }

  validateForm = () => {
    let valid = true;
    Object.values(this.state.errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    console.log(this.state.userRole);
    if (valid) {
      const payload = {
        userEmail: this.state.userName,
        role: parseInt(this.state.userRole),
        createdBy: getUserEmail(),
      };

      (async function adduser(that) {
        try {
          const result = await addUser(payload);
          if (result.Success) {
            that.props.getalluser();
            notification["success"]({
              message: "User added Successfully",
            });
          } else if (result.Error) {
            notification["error"]({
              message: "User already exist",
              description: "Please Enter different User Name",
            });
          }
        } catch (e) {
          console.error(e);
          checkError(e, that.props.logout);
          //notification["error"]({
          //message: "Server Error: Please check network connection",
          //    });
        }
      })(this);

      this.props.handleCancel();
    }
  };

  render() {
    return (
      <Modal
        title="Add User"
        centered
        visible={this.props.show}
        onOk={this.handleok}
        onCancel={this.props.handleCancel}
        okText="Add User"
      >
        <p>
          <label>User Name</label>
          <Input
            placeholder="Enter User Name"
            type="text"
            value={this.state.customerName}
            name="userName"
            onChange={(e) => this.handleChange(e)}
          ></Input>
          <span className="text-danger">{this.state.errors.userName}</span>
          <br />
          <span className="text-danger">{this.state.errors.userNameError}</span>
        </p>

        <p>
          <label>Role</label>
          <span> </span>
          <br />
          <Select
            value={this.state.userRole}
            name="role"
            onChange={(value) => this.handleUserRole(value)}
            style={{ width: 240 }}
          >
            <Option value={chconstants.USERROLE}>Basic</Option>
            <Option value={chconstants.ADMINUSERROLE}>Admin</Option>
          </Select>
          <br />
          <span className="text-danger">{this.state.errors.userRole}</span>
        </p>
      </Modal>
    );
  }
}

export default AddCHUser;
