"use strict";

var validNamePattern = /^[a-z0-9_-]+$/;
var validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
function checkPattern(value){
let cust_result = validNamePattern.test(value);
//console.log(cust_result, validNamePattern.test('ibm'));
return cust_result
}
export default function validateCustomerForm({
  customerName,
  partnerName,
  comments,
  createdBy,
}) {
  const errors = {
    customerName: "",
    partnerName: "",
    comments: "",
    createdBy: "",
  };
  var validNamePattern = /^[a-z0-9_-]+$/;
  const partner_result = validNamePattern.test(partnerName)
  const cust_result = validNamePattern.test(customerName);
  if (!customerName) {
    errors.customerName = "Please Enter Customer Name";
    console.log(errors.customerName)
  } 
  else {
// const cust_result = checkPattern(customerName)
if (cust_result === false || customerName.length>100) {
    errors.customerName = " Invalid Customer Name (Accepted characters are: a-z, 0-9, - , _ ,and Max 100 Characters)";
    console.log( errors.customerName)

}  }
  //const partner_result = validNamePattern.test(partnerName)
  if (!partnerName) {
    errors.partnerName = "Please Enter Partner Name";
    console.log(errors.partnerName)
  } 
  else if (partner_result === false || partnerName.length>100) {
    errors.partnerName = "Invalid Partner Name (Accepted characters are: a-z, 0-9, - , _ ,and Max 100 Characters)";
    console.log( errors.partnerName)

  }
  if (!comments) {
    errors.comments = "Please Enter Comments";
    //console.log(errors.comments)
  }

  // if (!createdBy)
  // {
  //   errors.createdBy="Please Enter Email ";
  //   console.log(errors.createdBy)
  // }
  // else if((!(createdBy.match(validEmailPattern))) || (createdBy.length > 100)){
  //   console.log('Input email is not valid');
  //   errors.createdBy="Invalid Email";
  // }

  return errors;
}

