import axios from "axios";
import { apiConfig } from "../../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";

import {
  URL_PREFIX,
  AUTHENTICATE_USER,
  GET_ALL_USERS,
  GET_ALL_CUSTOMERS,
  GET_ALL_PROJECTS,
  GET_ALL_DEPLOYMENTS,
  GET_ALL_BUILDS,
  GET_ALL_BRANCH_AND_TAGS,
  GET_ALL_FORMFACTORS,
  GET_ALL_SUBSCRIPTIONS_PER_DEPLOYMENT,
  GET_SUBSCRIPTION_LICENSE,
  ADD_USER,
  ADD_CUSTOMER,
  ADD_PROJECT,
  ADD_DEPLOYMENT,
  ADD_SUBSCRIPTION,
  ADD_TRIAL_SUBSCRIPTION,
  TRIGGER_BUILD,
  GET_BUILD_URL,
} from "./apiURL";

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  console.log(item.value);
  return item.value;
}

export function getUserEmail() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();
  const { username } = accounts[0];
  return username;
}

async function getToken() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();
  const request = {
    ...apiConfig,
    account: accounts[0],
  };
  const resp = await msalInstance.acquireTokenSilent(request);

  return resp.accessToken;
}

async function getHeaders() {
  const headers = {};
  const token = await getToken();
  const bearer = `Bearer ${token}`;
  headers.Authorization = bearer;

  //headers.id_token = getWithExpiry("id_token")
  return headers;
}

export async function authenticateUser() {
  const headers = await getHeaders();
  const response = await axios.post(AUTHENTICATE_USER, null, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function getAllUsers() {
  const headers = await getHeaders();
  const response = await axios.get(GET_ALL_USERS, { headers: headers });
  const result = await response;
  return result.data;
}

export async function getAllCustomers() {
  const headers = await getHeaders();
  const response = await axios.get(GET_ALL_CUSTOMERS, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function getAllProjects() {
  const headers = await getHeaders();
  const response = await axios.get(GET_ALL_PROJECTS, { headers: headers });
  const result = await response;
  return result.data;
}

export async function getAllDeployments() {
  const headers = await getHeaders();
  const response = await axios.get(GET_ALL_DEPLOYMENTS, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function getAllBuilds() {
  const headers = await getHeaders();
  const response = await axios.get(GET_ALL_BUILDS, { headers: headers });
  const result = await response;
  return result.data;
}

export async function addTrialSubscription(payload) {
  const headers = await getHeaders();
  const response = await axios.post(ADD_TRIAL_SUBSCRIPTION, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function getAllBranchAndTags() {
  const headers = await getHeaders();
  const response = await axios.get(GET_ALL_BRANCH_AND_TAGS, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function getAllFormFactors() {
  const headers = await getHeaders();
  const response = await axios.get(GET_ALL_FORMFACTORS, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function getBuildUrl(payload) {
  const headers = await getHeaders();
  const response = await axios.post(GET_BUILD_URL, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}
export async function getAllSubscriptionsPerDeployment(payload) {
  const headers = await getHeaders();
  const response = await axios.post(
    GET_ALL_SUBSCRIPTIONS_PER_DEPLOYMENT,
    payload,
    { headers: headers }
  );
  const result = await response;
  return result.data;
}

export async function getSubscriptionLicense(payload) {
  const headers = await getHeaders();
  const response = await axios.post(GET_SUBSCRIPTION_LICENSE, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function addUser(payload) {
  const headers = await getHeaders();
  const response = await axios.post(ADD_USER, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function addCustomer(payload) {
  const headers = await getHeaders();
  const response = await axios.post(ADD_CUSTOMER, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function addProject(payload) {
  const headers = await getHeaders();
  const response = await axios.post(ADD_PROJECT, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function addDeployment(payload) {
  const headers = await getHeaders();
  const response = await axios.post(ADD_DEPLOYMENT, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function addSubscription(payload) {
  const headers = await getHeaders();
  const response = await axios.post(ADD_SUBSCRIPTION, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}

export async function triggerBuild(payload) {
  const headers = await getHeaders();
  const response = await axios.post(TRIGGER_BUILD, payload, {
    headers: headers,
  });
  const result = await response;
  return result.data;
}
