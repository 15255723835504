
export default function getUTCTimestampFromDateString(date){
    const [day,month,year] = date.split('-');

    const new_Date =  year+"-"+month+"-"+day;
    let utctimestamp = new Date(new_Date).getTime()
    const UTCTimestamp = utctimestamp/1000;
    
    return UTCTimestamp;
  
  }