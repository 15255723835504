var validEmailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

export default function validateDeploymentForm({
    email,
    urlexpiry
    }) {
  
    const errors = {
        email: "",
        urlexpiry:"",
    };
  
    if (!email) {
      errors.email = "Please Enter Email";
      console.log(errors.email);
    } 
    else if(!(email.match(validEmailPattern)))
    {
      errors.email = "Invalid Email";
      console.log(errors.email);
    }

    if (!urlexpiry && urlexpiry!== 0) {
      errors.urlexpiry = "Please Enter Expiry";
      console.log(errors.urlexpiry);
    } 
    else if(urlexpiry <1 || urlexpiry>7)
    {
        errors.urlexpiry = "Invalid Expiry (Enter Expiry days Between 1-7)";
        console.log(errors.urlexpiry);
    }   

    return errors;
  }
