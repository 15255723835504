/* global gapi */
import React from "react";
import { notification, Button, Modal } from "antd";
import { BrowserRouter as Redirect } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

import "./App.css";
import { createBrowserHistory } from "history";
import Dashboard from "./App/components/Dashboard";
import Popup from "./App/components/sessionExpiryPopup";
//import getFormattedISTDateTimeFromTimestamp from "./App/utils/formatTimestamp"

import { SignInButton } from "./App/components/SignInButton";

//const {SetSignedInContext,Consumer} = React.createContext();

const history = createBrowserHistory();

export const App = (props) => {
  const isAuthenticated = useIsAuthenticated();

  const getWithExpiry = () => {
    console.log("getWithExpiry called for checking id_token");
    const itemStr = localStorage.getItem("id_token");
    // if(this.state.popup)
    // {
    //   console.log("popup true")
    //   this.warning()
    // }

    // if the item doesn't exist, return null
    if (!itemStr) {
      this.setState({
        isSignedIn: false,
        popup: false,
      });
      //this.warning(false)
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      console.log("Token expired Removing id_oken");
      localStorage.removeItem("id_token");
      localStorage.removeItem("email");
      this.setState({
        isSignedIn: false,
        popup: true,
      });
      //clearInterval(this.intervalID)
    }
    this.setState();
    this.setState({
      isSignedIn: true,
      popup: false,
    });
  };

  const warning = () => {
    Modal.warning({
      title: "Your Session is expired ",
      content: "Please Login again.....",
      onOk: Modal.destroyAll(),
      okText: "Login Again",
    });
  };

  const checkAuth = () => {
    if (!this.state.isSignedIn) {
      return <Redirect to="/login"></Redirect>;
    } else return <Redirect to="/dashboard"></Redirect>;
  };

  return <>{isAuthenticated ? <Dashboard /> : <SignInButton />}</>;
};

export default App;
