import React from "react";
import BuildTable from "../../components/JenkinsBuild/jenkinsBuildTable";
import { Button ,Tabs } from "antd";
import TriggerBuildForm from "../../components/JenkinsBuild/triggerBuildForm";
import getFormattedISTDateTimeFromTimestamp from "../../utils/formatTimestamp"
import { getAllBuilds } from '../../utils/api';
import { checkError } from '../../utils/responseErrorHandling'
import GenerateBuilURLForm from "../../components/JenkinsBuild/generateBuildUrlForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

var chconstants = require('../../utils/constants');

const TabPane = Tabs.TabPane;

class JenkinsBuild extends React.Component {
  state = {
    formVisible: false,
    buildData: [],
    buildUUID:"",
  };

 setFormVisible(isVisible,builduuid) {
    this.setState({ formVisible : isVisible, buildUUID : builduuid });
  }

  handleCancel= () => {

   this.setState({formVisible: false});

  }
 getallbuilds=()=>{
        (async function getAllbuilds(that){
      try{
        const result = await getAllBuilds();
        const new_buildData=that.SetTimestamp(result)
        new_buildData.map((build)=>  {
            (build.subscriptiontype === chconstants.SHARED? build.subscriptiontype = "Shared":
            build.subscriptiontype = "User")
            
                if(build.jenkinsbuildstatus === chconstants.JENKINSBUILDFAILEDTOSTART)
            {
                build.jenkinsbuildstatus = "Build Failed to Start"
            }
            else if(build.jenkinsbuildstatus === chconstants.JENKINSBUILDSTARTED)
            {
                build.jenkinsbuildstatus = "Build Started"
            }
            else if(build.jenkinsbuildstatus === chconstants.JENKINSBUILDSUCCESS)
            {
                build.jenkinsbuildstatus = "Build Success"
            }
            else if(build.jenkinsbuildstatus === chconstants.JENKINSBUILDFAILED)
            {
                build.jenkinsbuildstatus = "Build Failed"
            }
	   
            build['download']=<Button onClick={() => that.setFormVisible(true,build.builduuid)} > 
	    <FontAwesomeIcon icon={faDownload} />  </Button>

            
             })
	that.setState({buildData : new_buildData})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);
      }
    })(this);

}

  componentDidMount(){
        this.getallbuilds();
    }

    SetTimestamp = (builds) =>{
        var val;
        builds.map((item) => (
        val=this.GetTimestamp(item.createdon),
        item.createdon=val
        ))
    
        return builds;
      }
      GetTimestamp = ( createdon ) => {
        const {
          day,
          monthName,
          year,
          hours,
          minutes,
          seconds,
        } = getFormattedISTDateTimeFromTimestamp(createdon);
        return (
          day+" "+monthName+" "+year+" "+hours+":"+minutes+":"+seconds
        );
      };

      render() {
        return (
          <React.Fragment>
	  {this.state.formVisible? <GenerateBuilURLForm
            buildUUID={this.state.buildUUID}
            show={this.state.formVisible}
            logout={this.props.logout}
            handleCancel={this.handleCancel} />:null}
             

          <Tabs tabPosition='top' >
          <TabPane tab="Builds" key="1">
          <BuildTable builds={this.state.buildData} />
          </TabPane>
          <TabPane tab="Trigger Build" key="2">
          <TriggerBuildForm 
	    getallbuilds = {this.getallbuilds}
            logout={this.props.logout}/>
          </TabPane>
          
          </Tabs>
          </React.Fragment>
        );
      }
    }
    
    export default JenkinsBuild;
                                                                                                                              






