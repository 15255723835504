'use strict';

/**
 * Copyright (C) CloudHedge Technologies Private Limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and Confidential
 * @author Mahesh Lalge <mlalge@cloudhedge.io>
*/

exports.USERROLE = 1;
exports.ADMINUSERROLE = 2;
exports.NOROLE = 3;

exports.SHARED = 1;
exports.USER = 2;

exports.DEPLOYMENT_APPLIANCE = 1;
exports.DEPLOYMENT_SAAS = 2;

exports.HTTP_BAD_REQUEST = 400;
exports.HTTP_UNAUTHORIZED = 401;
exports.HTTP_FORBIDDEN = 403;

exports.JENKINSBUILDFAILEDTOSTART = 1;
exports.JENKINSBUILDSTARTED = 2;
exports.JENKINSBUILDSUCCESS = 3;
exports.JENKINSBUILDFAILED = 4;

exports.APP_DEPLOYMENT_UUID = 1516;

