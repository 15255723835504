import React from "react";
import { Button , Select, Input, notification} from "antd";
import validateTriggerBuildForm from "../../utils/validateTriggerBuildForm";
import { getAllBranchAndTags,
         getAllFormFactors,
         getAllCustomers,
         getAllProjects,
         getAllDeployments ,
         triggerBuild,
         getUserEmail
        } from '../../utils/api';
import { Row, Col } from "antd";
import { checkError } from '../../utils/responseErrorHandling'

const { Option } = Select;
const { TextArea } = Input;

var chconstants = require('../../utils/constants');
class TriggerBuildForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleok = this.handleok.bind(this);
  }
  state = {
    customerName: "",
    projectName: "",
    buildName: "",
    deploymentUUID: "",
    tag: "" ,
    branch:"",
    email:"",
    formFactor:"",
    subscriptionType: "",
    comments:"",
    branchData: [],
    formFactorData: [],
    customerData: [],
    projectData: [],
    deploymentData: [],

    errors: {
      customerName: "",
      projectName: "",  
      buildName: "",
      deploymentUUID: "",
      tag: "" ,
      branch:"",
      email:"",
      formFactor:"",
      subscriptionType: "",
      comments:""
    },
  };
  componentDidMount() {

    (async function getAllcustomers(that){
      try{
        const result = await getAllCustomers();
        that.setState({customerData : result})
      } catch(e){
        console.error(e);
        console.error(e.response.data)
        checkError(e,that.props.logout);
        }

    })(this);

    (async function getAllprojects(that){
      try{
        const result = await getAllProjects();
        that.setState({projectData : result})
      } catch(e){
        console.error(e);
        console.error(e.response.data)
        checkError(e,that.props.logout);

      }
    })(this);


    (async function getAlldeployments(that){
      try{
        const result = await getAllDeployments();
        that.setState({deploymentData : result})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);
      }
    })(this);

    (async function getAllbranchandtags(that){
      try{
        const result = await getAllBranchAndTags();
        that.setState({branchData : result})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);
      }
    })(this);

    (async function getAllformfactors(that){
      try{
        const result = await getAllFormFactors();
        that.setState({formFactorData : result})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);
      }
    })(this);
  }

  handleok = (event) => {
   event.preventDefault();
   const out_errors = validateTriggerBuildForm(this.state);

   this.setState({errors: out_errors },this.validateForm);

  };

  validateForm = () => {
    let valid = true;
    Object.values(this.state.errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    if(valid)
    {
      const payload = {
        subscriptionType: this.state.subscriptionType,
        deploymentUUID: this.state.deploymentUUID,
        buildName: this.state.buildName,
        email: this.state.email,
        branch: this.state.branch,
        tag: this.state.tag,
        formFactor: this.state.formFactor,
        comments: this.state.comments,
        createdBy: getUserEmail()
      };
      (async function triggerbuild(that){
        try{
          const result = await triggerBuild(payload);
          if(result.Success)
          {
	   that.props.getallbuilds();
            notification['success']({
              message: 'Build Triggered Successfully'
            });
          }
          else if(result.Error)
          {
            notification['error']({
              message: 'Build Name already exist',
              description:
                'Please Enter different Build Name',
            })
          }
        } catch(e){
          console.error(e);
          checkError(e,that.props.logout);
        }
      })(this);

     
    }
  };
  handleChange(e) {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }
  
  handleCustomerName(value) {
    this.setState({ customerName: value });
  }
  handleProjectName(value) {
    this.setState({ projectName: value });
  }
  handleFormFactor(value)
  {
    this.setState({formFactor:value})
  }
  handleBranch(value) {
    this.setState({branch: value});
  }
  handleTag(value) {
    this.setState({tag: value});
  }
  handleDeploymentUUID(value) {
    this.setState({ deploymentUUID: value });
  }
  handleSubscriptionType(value) {
    this.setState({ subscriptionType: value });
  }
  render() {
    return (
      <form onSubmit={this.handleok}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <label>Customer Name</label>
            <Select
              name="customerName"
              onChange={(value) => this.handleCustomerName(value)}
              value={this.state.customerName}
              style={{ width: 300 }}
            >
              {this.state.customerData.map((item) => (
                <Option key={item.customername} value={item.customername}>
                  {item.customername}
                </Option>
              ))}
            </Select>
            <br />
            <span className="text-danger">
              {this.state.errors.customerName}
            </span>
          </Col>
          <Col span={8}>
            <label>Project Name</label>
            <Select
              value={this.state.projectName}
              name="projectName"
              onChange={(value) => this.handleProjectName(value)}
              style={{ width: 300 }}
            >
              {this.state.projectData.map((item) =>
                item.customername === this.state.customerName ? (
                  <Option key={item.projectuuid} value={item.projectname}>
                    {item.projectname}
                  </Option>
                ) : (
                  " "
                )
              )}
            </Select>
            <br />
            <span className="text-danger">{this.state.errors.projectName}</span>
          </Col>
          <Col span={8}>
            <label>Deployment Name</label>
            <Select
              value={this.state.deploymentUUID}
              name="deploymentUUID"
              onChange={(value) => this.handleDeploymentUUID(value)}
              style={{ width: 300 }}
            >
              {this.state.deploymentData.map((item) =>
                item.projectname === this.state.projectName &&
                item.customername === this.state.customerName ? (
                  <Option key={item.deploymentuuid} value={item.deploymentuuid}>
                    {item.deploymentname}
                  </Option>
                ) : (
                  " "
                )
              )}
            </Select>
            <br />
            <span className="text-danger">
              {this.state.errors.deploymentUUID}
            </span>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
          <label>Subscription Type</label>
            <Select
              value={this.state.subscriptionType}
              name="subscriptionType"
              onChange={(value) => this.handleSubscriptionType(value)}
              style={{ width: 300 }}
            >
              <Option value={chconstants.SHARED}>Shared</Option>
              <Option value={chconstants.USER}>User</Option>
            </Select>
            <br />
            <span className="text-danger">
              {this.state.errors.subscriptionType}
            </span>
          </Col>
          <Col span={8}>
          <label>Build Name</label>
          <Input placeholder="Enter Build Name"
            type="text"
            value={this.state.buildName}
            name="buildName"
            onChange={(e) => this.handleChange(e)}
	     style={{ width: 300 }}
          ></Input>
          <span className="text-danger">{this.state.errors.buildName}</span>
          </Col>

          <Col span={8}>
          <label>Email</label>
        <Input placeholder="Enter Email"
            type="text"
            value={this.state.email}
            name="email"
            onChange={(e) => this.handleChange(e)}
	    style={{ width: 300 }}

          ></Input>
          <span className="text-danger">{this.state.errors.email}</span>
          </Col>
        </Row>
        
        <Row gutter={[16, 16]}>
          <Col span={8}>
          <label>Branch</label>
          <span>        </span><br/>
          <Select
            name="branch"
            onChange={(value) => this.handleBranch(value)}
            value={this.state.branch}
            style={{ width: 300 }}
          >
        {this.state.branchData.map((item) => (
              <Option key={item.branch} value={item.branch}>
                {item.branch}
              </Option>
            ))}
          </Select><br/>
          <span className="text-danger">{this.state.errors.branch}</span>
          </Col>
          <Col span={8}>
          <label>Tag</label>
          <span>        </span><br/>
          <Select
            value={this.state.tag}
            name="tag"
            onChange={(value) => this.handleTag(value)}
            style={{ width: 300 }}
          >
            {this.state.branchData.map((item) =>
              item.branch === this.state.branch ? (
                item.tags.map((tag)=>(
                <Option key={tag} value={tag}>
                  {tag}
                </Option>
                )
               )): (
                " "
              )
            )}
          </Select><br/>
          <span className="text-danger">{this.state.errors.tag}</span>
          </Col>
          <Col span={8}>
            
          <label>Form Factor</label>
          <span>        </span><br/>
          <Select
            name="formFactor"
            onChange={(value) => this.handleFormFactor(value)}
            value={this.state.formFactor}
            style={{ width: 300 }}
          >
        {this.state.formFactorData.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select><br/>
          <span className="text-danger">{this.state.errors.formFactor}</span>

          </Col>
        </Row>

        <label>Comments</label>
        <br />
        <TextArea
          rows={2}
          placeholder="Enter Comment"
          type="text"
          value={this.state.comments}
          name="comments"
          onChange={(e) => this.handleChange(e)}
          style={{ width: 300 }}
        ></TextArea>
        <br />
        <span className="text-danger">{this.state.errors.comments}</span>
        <br />

        <br />
        <Button style={{ float: "right" }} htmlType="submit">
          Trigger Build
        </Button>
      </form>

    );
  }
}

export default TriggerBuildForm;


