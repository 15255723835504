import React from "react";
import { Table } from "antd";

class DownloadSubscriptionTable extends React.Component {
  state = {
    subNameFilter: [],
    subTypeFilter: [],
  };

  static getDerivedStateFromProps(props) {
    if (props.subscriptions) {
      var subscriptionsData = props.subscriptions;
      var uniquesubNames = [
        ...new Set(
          subscriptionsData.map((subscription) => subscription.subscriptionname)
        ),
      ];
      var subNames = uniquesubNames.map((val) => ({ text: val, value: val }));

      var uniquesubtypes = [
        ...new Set(
          subscriptionsData.map((subscription) => subscription.subscriptiontype)
        ),
      ];
      var subTypes = uniquesubtypes.map((val) => ({ text: val, value: val }));

      return {
        subNameFilter: subNames,
        subTypeFilter: subTypes,
      };
    }

    return null;
  }

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "subscriptionname",
        key: "subscriptionname",
        sorter: (a, b) => a.subscriptionname.localeCompare(b.subscriptionname),
        sortDirections: ["ascend", "descend"],
        onFilter: (value, record) => record.subscriptionname === value,
        filters: this.state.subNameFilter,
      },
      {
        title: "UUID",
        dataIndex: "subscriptionuuid",
        key: "subscriptionuuid",
        sorter: (a, b) => a.subscriptionuuid.localeCompare(b.subscriptionuuid),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Type",
        dataIndex: "subscriptiontype",
        key: "subscriptiontype",
        sorter: (a, b) => a.subscriptiontype.localeCompare(b.subscriptiontype),
        sortDirections: ["ascend", "descend"],
        onFilter: (value, record) => record.subscriptiontype === value,
        filters: this.state.subTypeFilter,
      },
      {
        title: "User Email",
        dataIndex: "useremail",
        key: "useremail",
        sorter: (a, b) => a.useremail.localeCompare(b.useremail),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Benefits",
        dataIndex: "benefits",
        key: "benefits",
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        sorter: (a, b) => a.comments.localeCompare(b.comments),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Created by",
        dataIndex: "createdby",
        key: "createdby",
        sorter: (a, b) => a.createdby.localeCompare(b.createdby),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Created On",
        dataIndex: "createdon",
        key: "createdon",
        sorter: (a, b) => a.createdon.localeCompare(b.createdon),
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Download",
        dataIndex: "download",
        key: "download",
      },
    ];

    return (
      <React.Fragment>
        <Table
          dataSource={this.props.subscriptions}
          columns={columns}
          size="small"
          className="subscriptionTable"
          scroll={{ x: 1500 }}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            total: this.props.subscriptions.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            showQuickJumper: true,
          }}
          className="emptySubscriptionTable"
        />
        <br />
        <b>
          {" "}
          *Note : Please Select Customer Name,Project Name and Deployment Name
          to View Subscriptions
        </b>
      </React.Fragment>
    );
  }
}

export default DownloadSubscriptionTable;
