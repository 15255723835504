import React from "react";
import { Table, Input, DatePicker, InputNumber, Checkbox } from "antd";
import moment from "moment";

class AddSubscriptionTable extends React.Component {
  convertUTCToDate = (utc) => {
    const date = new Date(utc);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return day + "-" + month + "-" + year;
  };

  render() {
    const dataSource = [
      {
        rowTitle: "Count",
        discover: (
          <>
            <InputNumber
              style={{ width: 114 }}
              name="discoverLicenseCount"
              value={this.props.states.discoverLicenseCount}
              onChange={(value) =>
                this.props.handleCountNumbers(value, "discoverLicenseCount")
              }
            ></InputNumber>
            <br />
            <small className="text-danger">
              {this.props.errors.discoverLicenseCount}
            </small>
          </>
        ),
        transform: (
          <>
            <InputNumber
              style={{ width: 114 }}
              name="transformLicenseCount"
              value={this.props.states.transformLicenseCount}
              onChange={(value) =>
                this.props.handleCountNumbers(value, "transformLicenseCount")
              }
            ></InputNumber>
            <br />
            <small className="text-danger">
              {this.props.errors.transformLicenseCount}
            </small>
          </>
        ),
        cruize: (
          <>
            <InputNumber
              style={{ width: 114 }}
              name="cruizeLicenseCount"
              value={this.props.states.cruizeLicenseCount}
              onChange={(value) =>
                this.props.handleCountNumbers(value, "cruizeLicenseCount")
              }
            ></InputNumber>
            <br />
            <small className="text-danger">
              {this.props.errors.cruizeLicenseCount}
            </small>
          </>
        ),
        dockeronhost: (
          <>
            <InputNumber
              style={{ width: 114 }}
              name="dockeronhostLicenseCount"
              value={this.props.states.dockeronhostLicenseCount}
              onChange={(value) =>
                this.props.handleCountNumbers(value, "dockeronhostLicenseCount")
              }
            ></InputNumber>
            <br />
            <small className="text-danger">
              {this.props.errors.dockeronhostLicenseCount}
            </small>
          </>
        ),
        workload: (
          <>
            <InputNumber
              style={{ width: 114 }}
              name="workloadLicenseCount"
              value={this.props.states.workloadLicenseCount}
              onChange={(value) =>
                this.props.handleCountNumbers(value, "workloadLicenseCount")
              }
            ></InputNumber>
            <br />
            <small className="text-danger">
              {this.props.errors.workloadLicenseCount}
            </small>
          </>
        ),
        cloudlease: (
          <>
            <InputNumber
              style={{ width: 114 }}
              name="cloudleaseLicenseCount"
              value={this.props.states.cloudleaseLicenseCount}
              onChange={(value) =>
                this.props.handleCountNumbers(value, "cloudleaseLicenseCount")
              }
            ></InputNumber>
            <br />
            <small className="text-danger">
              {this.props.errors.cloudleaseLicenseCount}
            </small>
          </>
        ),
      },
      {
        rowTitle: "StartDate",
        discover: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.discoverStartDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="discoverStartDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "discoverStartDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.discoverStartDate}
            </small>
          </>
        ),
        transform: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.transformStartDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="transformStartDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "transformStartDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.transformStartDate}
            </small>
          </>
        ),
        cruize: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(this.props.states.cruizeStartDate * 1000),
                "DD-MM-YYYY"
              )}
              name="cruizeStartDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "cruizeStartDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.cruizeStartDate}
            </small>
          </>
        ),
        dockeronhost: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.dockeronhostStartDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="dockeronhostStartDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "dockeronhostStartDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.dockeronhostStartDate}
            </small>
          </>
        ),
        workload: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.workloadStartDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="workloadStartDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "workloadStartDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.workloadStartDate}
            </small>
          </>
        ),
        cloudlease: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.cloudleaseStartDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="cloudleaseStartDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "cloudleaseStartDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.cloudleaseStartDate}
            </small>
          </>
        ),
      },
      {
        rowTitle: "EndDate",
        discover: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(this.props.states.discoverEndDate * 1000),
                "DD-MM-YYYY"
              )}
              name="discoverEndDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "discoverEndDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.discoverDateError}
            </small>
            <small className="text-danger">
              {this.props.errors.discoverEndDate}
            </small>
          </>
        ),
        transform: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.transformEndDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="transformEndDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "transformEndDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.transformDateError}
            </small>
            <small className="text-danger">
              {this.props.errors.transformEndDate}
            </small>
          </>
        ),
        cruize: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(this.props.states.cruizeEndDate * 1000),
                "DD-MM-YYYY"
              )}
              name="cruizeEndDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "cruizeEndDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.cruizeDateError}
            </small>
            <small className="text-danger">
              {this.props.errors.cruizeEndDate}
            </small>
          </>
        ),
        dockeronhost: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.dockeronhostEndDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="dockeronhostEndDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "dockeronhostEndDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.dockeronhostDateError}
            </small>
            <small className="text-danger">
              {this.props.errors.dockeronhostEndDate}
            </small>
          </>
        ),
        workload: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(this.props.states.workloadEndDate * 1000),
                "DD-MM-YYYY"
              )}
              name="workloadEndDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "workloadEndDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.workloadDateError}
            </small>
            <small className="text-danger">
              {this.props.errors.workloadEndDate}
            </small>
          </>
        ),
        cloudlease: (
          <>
            <DatePicker
              value={moment(
                this.convertUTCToDate(
                  this.props.states.cloudleaseEndDate * 1000
                ),
                "DD-MM-YYYY"
              )}
              name="cloudleaseEndDate"
              allowClear={false}
              onChange={(date, dateString) =>
                this.props.handleDates(dateString, "cloudleaseEndDate")
              }
              format="DD-MM-YYYY"
            />
            <br />
            <small className="text-danger">
              {this.props.errors.cloudleaseDateError}
            </small>
            <small className="text-danger">
              {this.props.errors.cloudleaseEndDate}
            </small>
          </>
        ),
      },
    ];
    const columns = [
      {
        title: " ",
        dataIndex: "rowTitle",
        key: "rowTitle",
      },
      {
        title: "Discover",
        dataIndex: "discover",
        key: "discover",
      },
      {
        title: "Transform",
        dataIndex: "transform",
        key: "transform",
      },
      {
        title: "Cruize",
        dataIndex: "cruize",
        key: "cruize",
      },
      {
        title: "DockerOnHost",
        dataIndex: "dockeronhost",
        key: "dockeronhost",
      },
      {
        title: "PolymorphLinux",
        dataIndex: "workload",
        key: "workload",
      },
      {
        title: "DiscoverLite",
        dataIndex: "cloudlease",
        key: "cloudlease",
      },
    ];

    return (
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    );
  }
}

export default AddSubscriptionTable;
