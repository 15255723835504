
export const URL_PREFIX = 'http://100.25.138.240:3000';

export const AUTHENTICATE_USER = '/chauthenticate';

export const GET_ALL_USERS = '/chusers/getallusers';

export const GET_ALL_CUSTOMERS = '/chcustomers/getallcustomers';

export const GET_ALL_PROJECTS = '/chcustomers/getallprojects';

export const GET_ALL_DEPLOYMENTS = '/chdeployment/getalldeployments';

export const GET_ALL_BUILDS = '/chjenkinsbuild/getallbuilds';

export const GET_ALL_BRANCH_AND_TAGS = '/repository/getbranchandtags';

export const GET_ALL_FORMFACTORS = '/repository/getformfactors';

export const GET_ALL_SUBSCRIPTIONS_PER_DEPLOYMENT = '/subscription/getsubscriptionsperdeployment';

export const GET_SUBSCRIPTION_LICENSE = '/subscription/downloadappliancesubscription'; 

export const GET_BUILD_URL = '/chjenkinsbuild/generatebuildurl';

export const ADD_USER = '/chuser/adduser';

export const ADD_CUSTOMER = '/chcustomers/addcustomer';

export const ADD_PROJECT = '/chcustomers/addproject';

export const ADD_DEPLOYMENT = '/chdeployment/adddeployment';

export const ADD_SUBSCRIPTION = '/subscription/addsubscription';

export const ADD_TRIAL_SUBSCRIPTION ='/subscription/addtrialsubscriptiontoappdeployment';

export const TRIGGER_BUILD = '/chjenkinsbuild/triggerbuild';

