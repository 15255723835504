import React from "react";
import {
  Select,
  Input,
  Button,
  notification,
  Checkbox,
  DatePicker,
} from "antd";
import validateAddSubscriptionForm from "../../utils/validateAddSubscriptionForm";
import AddSubscriptionTable from "../../components/Subscriptions/addSubscriptionTable";
import SubscriptionForm from "../Subscriptions/subscriptionForm";
import getUTCTimestampFromDateString from "../../utils/formatUTCTimestamp";
import { addSubscription, addTrialSubscription } from "../../utils/api";
import {
  getAllCustomers,
  getAllProjects,
  getAllDeployments,
  getAllBuilds,
  getSubscriptionLicense,
  getUserEmail,
} from "../../utils/api";

import { checkError } from "../../utils/responseErrorHandling";

const FileDownload = require("js-file-download");

class AddSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleok = this.handleok.bind(this);
  }
  getDefaultDate = (duration) => {
    var date = new Date();
    date.setDate(date.getDate() + duration); // Set now + duration days as the new date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return day + "-" + month + "-" + year;
  };

  setDeploymentUUIDforTrial = () => {
    var deploymentUUID;
    if (this.props.isTrial) {
      this.state.deploymentData.map((item) => {
        if (
          item.deploymentname === "app-deployment" &&
          item.customername === "cloudhedge" &&
          item.projectname === "production"
        ) {
          deploymentUUID = item.deploymentuuid;
          this.handleSelectValues(deploymentUUID, "deploymentUUID");
        }
      });
    }
  };

  defaultStartDate = getUTCTimestampFromDateString(this.getDefaultDate(0));
  defaultEndDate = getUTCTimestampFromDateString(
    this.props.isTrial ? this.getDefaultDate(30) : this.getDefaultDate(365)
  );

  state = {
    commonDates: true,
    customerName: this.props.isTrial ? "cloudhedge" : "",
    projectName: this.props.isTrial ? "production" : "",
    deploymentUUID: "",
    buildUUID: "",
    subscriptionName: "",
    subscriptionType: this.props.isTrial ? 2 : "",
    userEmail: "",
    userName: "",
    discoverLicenseCount: 10,
    discoverStartDate: this.defaultStartDate,
    discoverEndDate: this.defaultEndDate,
    transformLicenseCount: 10,
    transformStartDate: this.defaultStartDate,
    transformEndDate: this.defaultEndDate,
    cruizeLicenseCount: 10,
    cruizeStartDate: this.defaultStartDate,
    cruizeEndDate: this.defaultEndDate,
    cloudleaseLicenseCount: 10,
    cloudleaseStartDate: this.defaultStartDate,
    cloudleaseEndDate: this.defaultEndDate,
    workloadLicenseCount: 10,
    workloadStartDate: this.defaultStartDate,
    workloadEndDate: this.defaultEndDate,
    dockeronhostLicenseCount: 10,
    dockeronhostStartDate: this.defaultStartDate,
    dockeronhostEndDate: this.defaultEndDate,
    comments: "",
    createdBy: "",
    customerData: [],
    projectData: [],
    deploymentData: [],
    buildData: [],

    errors: {
      customerName: "",
      projectName: "",
      deploymentUUID: "",
      buildUUID: "",
      subscriptionName: "",
      subscriptionType: "",
      discoverLicenseCount: "",
      discoverStartDate: "",
      discoverEndDate: "",
      discoverDateError: "",
      transformLicenseCount: "",
      transformStartDate: "",
      transformEndDate: "",
      transformDateError: "",
      cruizeLicenseCount: "",
      cruizeStartDate: "",
      cruizeEndDate: "",
      cruizeDateError: "",
      cloudleaseLicenseCount: "",
      cloudleaseStartDate: "",
      cloudleaseEndDate: "",
      cloudleaseDateError: "",
      workloadLicenseCount: "",
      workloadStartDate: "",
      workloadEndDate: "",
      workloadDateError: "",
      dockeronhostLicenseCount: "",
      dockeronhostStartDate: "",
      dockeronhostEndDate: "",
      dockeronhostDateError: "",
      userEmail: "",
      userName: "",
      comments: "",
      createdBy: "",
    },
  };

  //initialState = this.state;

  componentDidMount() {
    (async function getAllcustomers(that) {
      try {
        const result = await getAllCustomers();
        that.setState({ customerData: result });
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);

    (async function getAllprojects(that) {
      try {
        const result = await getAllProjects();
        that.setState({ projectData: result });
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);

    (async function getAlldeployments(that) {
      try {
        const result = await getAllDeployments();
        that.setState(
          { deploymentData: result },
          that.setDeploymentUUIDforTrial
        );
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);

    (async function getAllbuilds(that) {
      try {
        const result = await getAllBuilds();
        that.setState({ buildData: result });
      } catch (e) {
        console.error(e);
        console.error(e.response.data);
        checkError(e, that.props.logout);
      }
    })(this);
  }

  handleCommonStartDates = (startDate) => {
    const UTCTimestamp = getUTCTimestampFromDateString(startDate);
    this.setState({
      discoverStartDate: UTCTimestamp,
      transformStartDate: UTCTimestamp,
      cruizeStartDate: UTCTimestamp,
      cloudleaseStartDate: UTCTimestamp,
      workloadStartDate: UTCTimestamp,
      dockeronhostStartDate: UTCTimestamp,
    });
  };
  handleCommonEndDates = (endDate) => {
    const UTCTimestamp = getUTCTimestampFromDateString(endDate);
    this.setState({
      discoverEndDate: UTCTimestamp,
      transformEndDate: UTCTimestamp,
      cruizeEndDate: UTCTimestamp,
      cloudleaseEndDate: UTCTimestamp,
      workloadEndDate: UTCTimestamp,
      dockeronhostEndDate: UTCTimestamp,
    });
  };

  handleok = (event) => {
    event.preventDefault();
    const out_errors = validateAddSubscriptionForm(
      this.state,
      this.props.isTrial
    );
    this.setState({ errors: out_errors }, this.validateForm);
  };

  validateForm = () => {
    let valid = true;
    Object.values(this.state.errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );

    if (valid) {
      const payload = {
        subscriptionName: this.state.subscriptionName,
        subscriptionType: this.state.subscriptionType,
        deploymentUUID: this.state.deploymentUUID,
        userEmail: this.state.userEmail
          ? this.state.userEmail
          : "onprem@cloudhedge.io",
        discoverLicenseCount: this.state.discoverLicenseCount,
        discoverStartDate: this.state.discoverStartDate,
        discoverEndDate: this.state.discoverEndDate,
        transformLicenseCount: this.state.transformLicenseCount,
        transformStartDate: this.state.transformStartDate,
        transformEndDate: this.state.transformEndDate,
        cruizeLicenseCount: this.state.cruizeLicenseCount,
        cruizeStartDate: this.state.cruizeStartDate,
        cruizeEndDate: this.state.cruizeEndDate,
        cloudleaseLicenseCount: this.state.cloudleaseLicenseCount,
        cloudleaseStartDate: this.state.cloudleaseStartDate,
        cloudleaseEndDate: this.state.cloudleaseEndDate,
        workloadLicenseCount: this.state.workloadLicenseCount,
        workloadStartDate: this.state.workloadStartDate,
        workloadEndDate: this.state.workloadEndDate,
        dockeronhostLicenseCount: this.state.dockeronhostLicenseCount,
        dockeronhostStartDate: this.state.dockeronhostStartDate,
        dockeronhostEndDate: this.state.dockeronhostEndDate,
        comments: this.state.comments,
        createdBy: getUserEmail(),
      };

      if (this.props.isTrial) {
        payload.userName = this.state.userName;
      }

      (async function addsubscription(that) {
        try {
          const result = that.props.isTrial
            ? await addTrialSubscription(payload)
            : await addSubscription(payload);
          if (result.Success) {
            notification["success"]({
              message: "Subscription Added Successfully",
            });
            if (!that.props.isTrial) {
              that.downloadLicense(result.Success);
            }
          } else if (result.Error) {
            notification["error"]({
              message: "Failed to add Subscription",
            });
          }
        } catch (e) {
          console.error(e);
          notification["error"]({
            message: "Server Error: Please check network connection",
          });
        }
      })(this);
    }
  };
  downloadLicense = (successData) => {
    const split = successData.split(" ");
    const subscriptionID = split[split.length - 1];
    const payload = {
      subscriptionUUID: subscriptionID,
    };

    (async function getsubscriptionlicense(that) {
      try {
        const result = await getSubscriptionLicense(payload);

        if (result.Success)
          FileDownload(result.Success, `${subscriptionID}.lic`);

        if (result.Success)
          notification["success"]({
            message: "License Downloaded Successfully",
          });
      } catch (e) {
        console.error(e);
        checkError(e, that.props.logout);
      }
    })(this);
  };
  handleChange(e) {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }

  handleDates = (dateString, name) => {
    const UTCTimestamp = getUTCTimestampFromDateString(dateString);
    this.setState({ [name]: UTCTimestamp }, () => {});
  };
  handleCountNumbers = (count, name) => {
    this.setState({ [name]: count }, () => {});
  };
  handleCustomerName(value) {
    this.setState({ customerName: value });
  }
  handleProjectName(value) {
    this.setState({ projectName: value });
  }
  handleDeploymentUUID(value) {
    this.setState({ deploymentUUID: value });
  }
  handleSubscriptionType(value) {
    this.setState({ subscriptionType: value });
  }
  handlebuildUUID = (uuid, name) => {
    var subscriptiontype;
    this.state.buildData.map(
      (item) =>
        item.builduuid === uuid && (subscriptiontype = item.subscriptiontype)
    );
    this.setState({ [name]: uuid, subscriptionType: subscriptiontype });
  };

  handleSelectValues = (value, name) => {
    this.setState({ [name]: value });
  };

  render() {
    return (
      <form onSubmit={this.handleok}>
        <SubscriptionForm
          isTrial={this.props.isTrial}
          handleChange={this.handleChange}
          handleSelectValues={this.handleSelectValues}
          handlebuildUUID={this.handlebuildUUID}
          states={this.state}
        />
        <AddSubscriptionTable
          isTrial={this.props.isTrial}
          states={this.state}
          handleChange={this.handleChange}
          handleCountNumbers={this.handleCountNumbers}
          handleDates={this.handleDates}
          errors={this.state.errors}
        />
        <br />
        <span className="text-danger">
          {" "}
          {this.state.errors.licenseCount}{" "}
        </span>{" "}
        <br />
        <Checkbox
          onChange={() =>
            this.setState({ commonDates: !this.state.commonDates })
          }
        >
          Apply below Start date and End date to all
        </Checkbox>
        <br />
        <br />
        <label>Start Date </label>
        <DatePicker
          disabled={this.state.commonDates}
          name="commonStartDate"
          allowClear={false}
          onChange={(date, dateString) =>
            this.handleCommonStartDates(dateString)
          }
          style={{ width: 114 }}
          format="DD-MM-YYYY"
        />
        <span style={{ width: 70 }}> </span>
        <label>End Date </label>
        <DatePicker
          disabled={this.state.commonDates}
          name="commonEndDate"
          allowClear={false}
          onChange={(date, dateString) => this.handleCommonEndDates(dateString)}
          style={{ width: 114 }}
          format="DD-MM-YYYY"
        />
        <Button style={{ float: "right" }} htmlType="submit">
          {this.props.isTrial ? "Add Trial Subscription" : "Add Subscription"}
        </Button>
      </form>
    );
  }
}

export default AddSubscription;
