import React from "react";
import { Modal , Select, Input, notification} from "antd";
import validateDeploymentForm from "../../utils/validateDeploymentForm.js";
import { getAllCustomers,
         getAllProjects ,
         addDeployment,
         getUserEmail,
        } from '../../utils/api';

import { checkError } from '../../utils/responseErrorHandling'

const { Option } = Select;
const { TextArea } = Input;
var chconstants = require('../../utils/constants');
class DeploymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleok = this.handleok.bind(this);
  }
  state = {
    customerName: "",
    projectUUID: "",
    deploymentName: "" ,
    deploymentType: "",
    comments:"",
    createdBy: "",
    customerData: [""],
    projectData: [""],

    errors: {
      deploymentNameError:"",
      customerName: "",
      projectUUID: "",
      deploymentName: "",
      deploymentType: "",
      comments:"",
      createdBy: "",
    },
  };

  
  componentDidMount() {
  
    (async function getAllcustomers(that){
      try{
        const result = await getAllCustomers();
        that.setState({customerData : result})
      } catch(e){
        console.error(e);
	checkError(e,that.props.logout);  
      }
    })(this);

    (async function getAllprojects(that){
      try{
        const result = await getAllProjects();
        that.setState({projectData : result})
      } catch(e){
        console.error(e);
        checkError(e,that.props.logout);  
      }
    })(this);
    
    // axios
    //   .get("http://100.25.138.240:3000/chcustomers/getallprojects")
    //   .then((response) => {
    //     this.setState({ projectData: response.data });
    //     //console.log(this.state.projectData)
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  handleok = () => {
    const out_errors = validateDeploymentForm(this.state);
    if(this.state.errors.deploymentNameError) 
      out_errors.deploymentNameError = this.state.errors.deploymentNameError;


    this.setState({errors: out_errors },this.validateForm);
  
  };

  validateForm = () => {
    let valid = true;
    Object.values(this.state.errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    if(valid)
    {
      const payload = {
        deploymentType: parseInt(this.state.deploymentType),
        projectUUID: this.state.projectUUID,
        deploymentName: this.state.deploymentName,
        comments:this.state.comments,
        createdBy: getUserEmail()
      };
      (async function adddeployment(that){
        try{
          const result = await addDeployment(payload);
          if(result.Success)
          {
	    that.props.getalldeployments();
            notification['success']({
              message: 'Deployment added Successfully'
            });
          }
          else if(result.Error)
          {
            notification['error']({
              message: 'Deployment Name already exist',
              description:
                'Please Enter different Deployment Name',
            })
          }
        } catch(e){
          console.error(e);
	  checkError(e,that.props.logout);  
        }
      })(this);
  
      this.props.handleCancel();
    }
  };

  isNameExist = (name) => {
   if(name.length>=3)
    {
  
    var nameExist=false;
    this.props.deploymentData.map((deployment) => { 

    if(deployment.customername === this.state.customerName && deployment.projectuuid === this.state.projectUUID
      && deployment.deploymentname === name){
      nameExist=true
    }
    })

    if(nameExist) {
      this.state.errors.deploymentNameError = 
      "Deployment Name already exist, Please enter different name"
      }
      else{
        this.state.errors.deploymentNameError = ""
      }
   }
    else if(name.length<3 && this.state.errors.deploymentNameError)
    {
      this.state.errors.deploymentNameError = ""
    }
}
  
  handleChange(e) {
   if(e.target.name === "deploymentName")
      this.isNameExist(e.target.value);
      
 
   const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }

  handleCustomerName(value) {
    //const value = e.target.value;
    this.setState({customerName: value});
  }
  handleProjectUUID(value) {
    //const value = e.target.value;
    this.setState({projectUUID: value});
  }
  handleDeploymentType(value) {
    //const value = e.target.value;
    this.setState({deploymentType: value});
  }
  render() {
    return (
      <Modal
        title="Add Deployment"
        centered
        visible={this.props.show}
        onOk={this.handleok}
        onCancel={this.props.handleCancel}
        okText="Add Deployment"
      >
        <p>
          <label>Customer Name</label>
          <span>        </span><br/>
          <Select
            name="customerName"
            onChange={(value) => this.handleCustomerName(value)}
            value={this.state.customerName}
            style={{ width: 240 }}
          >
            {this.state.customerData.map((item) => (
              <Option key={item.customername} value={item.customername}>
                {item.customername}
              </Option>
            ))}
          </Select><br/>
          <span className="text-danger">{this.state.errors.customerName}</span>
        </p>
        <p>
          <label>Project Name</label>
          <span>        </span><br/>
          <Select
            value={this.state.projectUUID}
            name="projectUUID"
            onChange={(value) => this.handleProjectUUID(value)}
            style={{ width: 240 }}
          >
            {this.state.projectData.map((item) =>
              item.customername === this.state.customerName ? (
                <Option key={item.projectuuid} value={item.projectuuid}>
                  {item.projectname}
                </Option>
              ) : (
                " "
              )
            )}
          </Select><br/>
          <span className="text-danger">{this.state.errors.projectUUID}</span>
        </p>
        <p>
          <label>Deployment Name</label>
          <Input placeholder="Enter Deployment Name"
            type="text"
            value={this.state.deploymentName}
            name="deploymentName"
            onChange={(e) => this.handleChange(e)}
          ></Input>
          <span className="text-danger">{this.state.errors.deploymentName}</span><br/>
          <span className="text-danger">{this.state.errors.deploymentNameError}</span>

        </p>
        <p>
          <label>Deployment Type</label>
          <span>        </span><br/>
          <Select
            value={this.state.deploymentType}
            name="deploymentType"
            onChange={(value) => this.handleDeploymentType(value)}
            style={{ width: 240 }}
          >
            <Option value={chconstants.DEPLOYMENT_APPLIANCE}>Appliance</Option>
            <Option value={chconstants.DEPLOYMENT_SAAS}>SaaS</Option>
          </Select><br/>
          <span className="text-danger">{this.state.errors.deploymentType}</span>
        </p>
        <p>
          <label>Comments</label>
          <TextArea rows={2}  placeholder="Enter Comment"
            type="text"
            value={this.state.comments}
            name="comments"
            onChange={(e) => this.handleChange(e)}
          ></TextArea>
          <span className="text-danger">{this.state.errors.comments}</span>
        </p>
      </Modal>
    );
  }
}

export default DeploymentForm;
