import React from "react";
import { Modal, Input, notification,InputNumber } from "antd";
import validateGenerateBuildUrlForm from "../../utils/validateGenerateBuildUrlForm.js";
import { getBuildUrl } from '../../utils/api';
import { checkError } from '../../utils/responseErrorHandling'

class GenerateBuilURLForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleok = this.handleok.bind(this);
  }
  state = {
    email:"",
    urlexpiry:"",
    errors: {
        email:"",
        urlexpiry: ""
    },
  };

  handleok = () => {
    const out_errors = validateGenerateBuildUrlForm(this.state);
    this.setState({errors: out_errors },this.validateForm);
  };
  
  validateForm = () => {
    let valid = true;
    Object.values(this.state.errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    if(valid)
    {
      const payload = {
        buildUUID : this.props.buildUUID,
        email: this.state.email,
        urlexpiry: parseInt(this.state.urlexpiry)
      };
      (async function generatebuildurl(that){
        try{
          const result = await getBuildUrl(payload);
          if(result.Success)
          {
            notification['success']({
              message: 'Successfully Generated Build details'
            });
          }
          else if(result.Error)
          {
            notification['error']({
              message: 'Failed to generate the build details'
            })
          }
        } catch(e){
          console.error(e);
          checkError(e,that.props.logout);
        }
      })(this);
  
      this.props.handleCancel();
    }
  };
  
  handleChange(e) {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  }
 
  render() {
    return (
      <Modal
        title="Get Build Details"
        centered
        visible={this.props.show}
        onOk={this.handleok}
        onCancel={this.props.handleCancel}
        okText="Get Build Details"
      >
        <p>
          <label>Email</label>  
          <Input placeholder="Enter Email"
            type="text"
            value={this.state.email}
            name="email"
            onChange={(e) => this.handleChange(e)}
          ></Input>
          <span className="text-danger">{this.state.errors.email}</span>
        </p>
        <p>
          <label>Expiry Days</label><br/>
	<InputNumber
              placeholder="Enter Expiry Days Between 1-7"
              name="urlexpiry"
              value={this.state.urlexpiry}
              onChange={(value)=>this.setState({urlexpiry: value})}
              
	      style={{ width: 470 }}
            ></InputNumber> <br/>
	<span className="text-danger">{this.state.errors.urlexpiry}</span>
        </p>
      </Modal>
    );
  }
}

export default GenerateBuilURLForm;

