import React from "react";
import { Table } from "antd";

class ProjectTable extends React.Component{
    constructor(props)
  {
    super(props)
  }
   state={
  customerNameFilter:[],
  projectNameFilter:[]
  }
  static getDerivedStateFromProps(props)
  {
  
    if(props.projects)
    {
    var projectData=props.projects;
    var uniqueCustomerNames = [...new Set(projectData.map(project => project.customername))];
    var custFilter = uniqueCustomerNames.map(val => ({text:val,value:val}))


    var uniqueProjectNames = [...new Set(projectData.map(project => project.projectname))];
    var projFilter = uniqueProjectNames.map(val => ({text:val,value:val}))

    return {
      customerNameFilter:custFilter,
      projectNameFilter:projFilter
    };
    }

    return null;
  }
/*
    componentDidMount()
   {
      
      var projectData=this.props.projects;
    if(this.props.projects)
      { 
      var uniqueCustomerNames = [...new Set(projectData.map(project => project.customername))];
      uniqueCustomerNames.map(val => this.state.customerNameFilter.push({text:val,value:val}))


      var uniqueProjectNames = [...new Set(projectData.map(project => project.projectname))];
      uniqueProjectNames.map(val => this.state.projectNameFilter.push({text:val,value:val}))
}
    } */  
 
    render(){          
        
     /* var projectData=this.props.projects;
      const customerNameFilter=[],projectNameFilter=[];

      var uniqueCustomerNames = [...new Set(projectData.map(project => project.customername))];
      uniqueCustomerNames.map(val => customerNameFilter.push({text:val,value:val}))


      var uniqueProjectNames = [...new Set(projectData.map(project => project.projectname))];
      uniqueProjectNames.map(val => projectNameFilter.push({text:val,value:val}))
*/
 
	 const columns = [
            {
              title: 'Customer Name',
              dataIndex: 'customername',
              key: 'customername',
	      sorter: (a, b) => a.customername.localeCompare(b.customername),
              sortDirections: [ 'ascend','descend'],
	      onFilter: (value, record) => record.customername === value,
	     filters:this.state.customerNameFilter
            },
            {
              title: 'Project Name',
              dataIndex: 'projectname',
              key: 'projectname',
	      sorter: (a, b) => a.projectname.localeCompare(b.projectname),
              sortDirections: ['ascend','descend'],
	      onFilter: (value, record) => record.projectname === value,
              filters:this.state.projectNameFilter
            },
            {
              title: 'Comments',
              dataIndex: 'comments',
              key: 'comments',
	      sorter: (a, b) => a.comments.localeCompare(b.comments),
              sortDirections: ['ascend','descend'],
            },
            {
              title: 'Created by',
              dataIndex: 'createdby',
              key: 'createdby',
	      sorter: (a, b) => a.createdby.localeCompare(b.createdby),
              sortDirections: ['ascend','descend'],
            },
            {
              title: 'Created On',
              dataIndex: 'createdon',
              key: 'createdon',
	      sorter: (a, b) => a.createdon.localeCompare(b.createdon),
              sortDirections: [ 'ascend','descend'],
            },
            
          ];
        return(
            <Table dataSource={this.props.projects} columns={columns} className="emptyDescription"
	     pagination = {{ showSizeChanger: true, pageSizeOptions: ['10', '20', '30','40','50'],
           total: this.props.projects.length, defaultPageSize:10,
          showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showQuickJumper: true }}  />
        )
        }
}

export default ProjectTable;

